import React, { createContext, useReducer, PropsWithChildren, FC, Dispatch } from 'react';
import { PrimitiveType } from 'react-intl';

import { Color } from 'Components/ui/types';

type ActionsDataProps = {
  id: string;
  text?: string;
  values?: Record<string, PrimitiveType>;
  btnCaption?: string;
  className?: string;
  callback?: (preventUpdate?: boolean) => void;
  position?: 'center' | 'left' | 'right';
  color?: Color;
  removeDelay?: number;
  component?: JSX.Element;
};

export type NotificationsActionsDataProps = ActionsDataProps;

interface NotificationsStateProps {
  items: ActionsDataProps[];
}

const initialValue: NotificationsStateProps = {
  items: [],
};

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum Types {
  ADD_NOTIFICATION = 'ADD_NOTIFICATION',
  REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION',
}

type NotificationsPayload = {
  [Types.ADD_NOTIFICATION]: {
    item: ActionsDataProps;
  };
  [Types.REMOVE_NOTIFICATION]: {
    itemId: string;
  };
};

export type NotificationsActions =
  ActionMap<NotificationsPayload>[keyof ActionMap<NotificationsPayload>];

type Reducer = (
  state: NotificationsStateProps,
  action: NotificationsActions
) => NotificationsStateProps;
const reducer: Reducer = (state, action) => {
  switch (action.type) {
    case Types.ADD_NOTIFICATION: {
      const newItem = action.payload.item;

      const exist = state.items.some((item) => item.id === newItem.id);
      if (exist) return state;

      return {
        ...state,
        items: [...state.items, newItem],
      };
    }
    case Types.REMOVE_NOTIFICATION: {
      const idForRemove = action.payload.itemId;
      const newItems = state.items.filter((item) => item.id !== idForRemove);
      return {
        ...state,
        items: newItems,
      };
    }
    default:
      return state;
  }
};

const NotificationsContext = createContext<{
  state: NotificationsStateProps;
  dispatch: Dispatch<NotificationsActions>;
  showNotification: (item: ActionsDataProps) => void;
  hideNotification: (id: string) => void;
}>({
  state: initialValue,
  dispatch: () => null,
  showNotification: () => null,
  hideNotification: () => null,
});

type NotificationsContextProviderType = FC<PropsWithChildren<{}>>;

const NotificationsContextProvider: NotificationsContextProviderType = (props) => {
  const { children } = props;

  const [state, dispatch] = useReducer<Reducer>(reducer, initialValue);

  const showNotification = (item: ActionsDataProps) => {
    dispatch({
      type: Types.ADD_NOTIFICATION,
      payload: { item },
    });
  };

  const hideNotification = (id: string) => {
    dispatch({
      type: Types.REMOVE_NOTIFICATION,
      payload: { itemId: id },
    });
  };

  return (
    <NotificationsContext.Provider value={{ state, dispatch, showNotification, hideNotification }}>
      {children}
    </NotificationsContext.Provider>
  );
};

export { NotificationsContext, NotificationsContextProvider };
export type { NotificationsContextProviderType };
