import React, { useState } from 'react';
import { graphql, useFragment } from 'react-relay';

import styles from './Comment.pcss';

import updateProject from 'Mutations/UpdateProject.Mutation';
import Text from 'Components/ui/Text/Text';
import TextButton from 'Atoms/TextButton/TextButton';
import Field from 'Molecules/Field/Field';
import { amplitude } from 'Helpers/amplitude';
import { Comment_project$key } from 'GraphTypes/Comment_project.graphql';

let interval: NodeJS.Timeout;

interface Props {
  project: Comment_project$key;
  isAdmin: boolean;
  className: string;
}

const Comment = (props: Props) => {
  const { project, isAdmin, className } = props;

  const data = useFragment(
    graphql`
      fragment Comment_project on Project {
        id
        comment
        adminComment
      }
    `,
    project
  );

  const id = data?.id;
  const comment = data?.comment;
  const adminComment = data?.adminComment;

  const commentValue = isAdmin ? adminComment : comment;

  const [showField, setShowField] = useState(!!commentValue);

  const handleChange = (data: { [x: string]: string }) => {
    clearInterval(interval);
    interval = setInterval(() => {
      updateProject({ id, ...data });
      amplitude.sendEvent({
        id: '252',
        category: 'project',
        name: 'add_note',
      });
      clearInterval(interval);
    }, 500);
  };

  const handleAddClick = () => {
    setShowField(true);
  };

  return (
    <div className={`${className} ${styles.root}`}>
      <div className={styles.header}>
        <Text msg="chat.add_note" className={styles.title} data-test="comment:text:addNote" />
        {!showField && (
          <TextButton
            color="normal"
            iconName="plus"
            onClick={handleAddClick}
            data-test="comment:textButton:unknown"
          />
        )}
      </div>
      {showField && (
        <Field
          element="input"
          name={isAdmin ? 'adminComment' : 'comment'}
          elementData={{
            defaultValue: commentValue,
            multiple: true,
            style: { height: '96px' },
            onChange: handleChange,
          }}
          className={styles.textarea}
        />
      )}
    </div>
  );
};

export default Comment;
