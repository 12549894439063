import React, { useRef, useState, useEffect } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import classnames from 'classnames';
import Textarea from 'react-textarea-autosize';

import styles from './Input.pcss';

import Text from 'Components/ui/Text/Text';
import Spinner from 'Atoms/Spinner/Spinner';
import { inputCallback, InputProps } from 'Types/common';

type inputEvent =
  | React.FocusEvent<HTMLInputElement>
  | React.ChangeEvent<HTMLInputElement>
  | React.FocusEvent<HTMLTextAreaElement>
  | React.ChangeEvent<HTMLTextAreaElement>;

let isFocused = false;

type AdditionalProps = {
  maxlengthLabel?: boolean;
  forceDefaultSet?: boolean;
};

const Input: React.FC<InputProps & WrappedComponentProps & AdditionalProps> = (props) => {
  const {
    autoFocus,
    className,
    defaultValue,
    disabled,
    error,
    intl,
    maxlength,
    maxlengthLabel = true,
    multiple,
    name,
    tabIndex,
    type = 'text',
    placeholder,
    placeholderMsg,
    placeholderValues,
    style,
    textAreaData,
    value,
    valueValidation,
    loading,
    onChange,
    onBlur,
    onFocus,
    onEnterKeyDown,
    additionalComponent,
    dataTest,
    forceDefaultSet,
  } = props;

  /*@TODO Resolve any type*/
  const inputEl = useRef<any>(null);

  const defaultLength = defaultValue ? String(defaultValue).length : undefined;
  const [length, setLength] = useState<undefined | boolean | number>(defaultLength);

  useEffect(() => {
    if (defaultValue && inputEl && inputEl.current) {
      inputEl.current.value = defaultValue;
    }
    if (forceDefaultSet && inputEl && inputEl.current) {
      inputEl.current.value = defaultValue;
    }
  }, [defaultValue]);

  useEffect(() => {
    if (onEnterKeyDown) {
      document.addEventListener('keydown', handleKeyDown);
    }

    if (inputEl && inputEl.current) {
      setLength(inputEl.current.value.length);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode === 13 && onEnterKeyDown && isFocused) {
      if (inputEl && inputEl.current) {
        const value = inputEl.current?.value;
        if (valueValidation && !valueValidation(value)) {
          return;
        }
        onEnterKeyDown(value);
        inputEl.current.value = '';
      }
    }
  };

  const handleEvent = (event: inputEvent, callback: inputCallback | undefined) => {
    const { value } = event.target;
    if (callback) {
      const data = name ? { [name]: value } : value;
      callback(data);
    }
  };

  const handleBlur = (
    event: React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement>
  ) => {
    if (onEnterKeyDown) {
      isFocused = false;
    }
    handleEvent(event, onBlur);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (maxlength) {
      const length = event.target.value.length;
      setLength(length);

      if (type === 'number' && length > maxlength) {
        setLength(maxlength);
        event.target.value = event.target.value.slice(0, maxlength);
      }
    }

    handleEvent(event, onChange);
  };

  const handleFocus = (
    event: React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement>
  ) => {
    if (onEnterKeyDown) {
      isFocused = true;
    }
    handleEvent(event, onFocus);
  };

  const classes = classnames(className, styles.content, {
    [styles.loading]: loading,
    [styles.withMaxlength]: maxlength,
    [styles.multiple]: multiple,
    [styles.error]: error,
  });

  const rootClases = classnames(styles.root, {
    [styles.error]: error,
  });

  const placeholderText = placeholderMsg
    ? intl.formatMessage({ id: placeholderMsg }, placeholderValues)
    : placeholder;

  const tag = multiple ? Textarea : 'input';
  return (
    <div className={`${styles.wrap}`}>
      <div className={rootClases}>
        {React.createElement(tag, {
          className: classes,
          autoComplete: 'off',
          autoFocus,
          defaultValue: defaultValue !== null ? defaultValue : undefined,
          disabled: disabled || loading,
          maxLength: maxlength,
          name,
          placeholder: placeholderText as string,
          ref: inputEl,
          tabIndex,
          type,
          value,
          style,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          'data-test': dataTest,
          ...textAreaData,
          onBlur: handleBlur,
          onChange: handleChange,
          onFocus: handleFocus,
        })}
      </div>
      {maxlength && maxlengthLabel && (
        <Text
          type="label"
          color="grey"
          text={`${length || 0}/${maxlength}`}
          className={styles.limit}
        />
      )}
      {additionalComponent && <div className={styles.limit}>{additionalComponent}</div>}
      {loading && <Spinner size="sm" className={styles.preloader} />}
    </div>
  );
};

export default injectIntl(Input);
