import React, { useMemo, useContext } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { useLocation } from 'react-router-dom';

import BriefContent from '../BriefContent/BriefContent';
import ProjectAcceptActions from '../ProjectAcceptActions/ProjectAcceptActions';
import CampaignOutreachInactiveBanner from '../CampaignOutreachContent/CampaignOutreachInactiveBanner/CampaignOutreachInactiveBanner';

import Spinner from 'Atoms/Spinner/Spinner';
import { ProjectForOfferQuery as QueryItem } from 'GraphTypes/ProjectForOfferQuery.graphql';
import { BARTER_TYPE, CONTRACTOR } from 'Constants/general';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import OutreachApplicationSent from 'Modal/contractor/OutreachApplicationSent/OutreachApplicationSent';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';
import { modalName } from 'Types/modals';
import { amplitude } from 'Helpers/amplitude';

const ProjectForOfferQuery = graphql`
  query ProjectForOfferQuery($projectId: ID!, $token: String) {
    currentUser {
      id
      type
      contractorProfile {
        tiktokShopLinked
      }
    }
    projectForOffer(id: $projectId, token: $token) {
      id
      currency
      offer {
        id
        acceptance {
          id
        }
        rejection {
          id
        }
      }
      campaign {
        id
        type
        useCase
        name
        stage
        platform
        organization {
          tiktokShopLinked
        }
        cover {
          ... on Image {
            transformations {
              collageThumbnailUrl
            }
          }
        }
        paymentType
        name
        type
        platform
        brand {
          name
          summary
          websiteUrl
          logo {
            ... on Image {
              transformations {
                brandLogoUrl
              }
            }
          }
        }
        brief {
          __typename
          ... on V2Brief {
            productDescription
            productDelivery
            productUrl
            paidSocialBca
            paidSocial
            paidSocialActivationDays
            sparkAdsActivationDays
            priceLimitMin
            priceLimitMax
            includeReferralFee
            referralFeeRate
          }
        }
        ...BriefContent_campaign
        ...CreativeList_campaign
        ...BriefInfo_campaign
        ...BriefContent_campaign
      }
      paidSocial
      creator {
        username
        ... on InstagramCreator {
          paidSocialEnabled
          insightsAuthorized
        }
        ... on TiktokCreator {
          tiktokAdsAuthorized
        }
      }
      brief {
        id
        __typename
        ... on V2Brief {
          productDelivery
          paidSocialBca
        }
      }
    }
  }
`;

interface Props {
  projectForOfferId?: string;
  isOwnership?: boolean;
  hasOwnership?: boolean;
  shortenView?: boolean;
  showAcceptActionOnly?: boolean;
}

const ProjectForOffer: React.FC<Props> = (props) => {
  const { isOwnership, hasOwnership, projectForOfferId, shortenView, showAcceptActionOnly } = props;
  const { search } = useLocation();

  const token = useMemo<string>(() => {
    const sp = new URLSearchParams(search);
    return sp.get('token') || '';
  }, [search]);

  const { openDrawer } = useContext(DrawerContext);
  const { dispatch: modalDispatch } = useContext(ModalContext);

  const queryProps = useLazyLoadQuery<QueryItem>(ProjectForOfferQuery, {
    projectId: projectForOfferId || '',
    token,
  });
  if (!queryProps) return <Spinner style={{ margin: '40px auto' }} />;

  const currentUser = queryProps.currentUser?.id;
  const currentUserType = queryProps?.currentUser?.type;

  const project = queryProps.projectForOffer;
  const projectId = project?.id;

  const currency = project?.currency;
  const brief = project?.brief;
  const campaign = project?.campaign;
  const campaignId = project?.campaign?.id;
  const campaignPlatform = project?.campaign.platform;
  const campaignType = project?.campaign.type;

  const paymentType = project?.campaign.paymentType;
  const isBarter = paymentType === BARTER_TYPE;
  const brandName = project?.campaign?.brand?.name;

  const offerId = project?.offer?.id;

  const isCampaignActive = project?.campaign?.stage === 'ACTIVE';
  const needAcceptance =
    project?.offer && !project?.offer.acceptance && !project.offer.rejection && isCampaignActive;

  const paidSocial = project?.paidSocial;
  const paidSocialBca = project?.brief?.paidSocialBca;
  const paidSocialEnabled = project?.creator?.paidSocialEnabled;
  const insightsAuthorized = project?.creator?.insightsAuthorized;

  const productDelivery = brief?.productDelivery;

  const needSignin = !hasOwnership && !currentUser;

  const needLogout = !!(hasOwnership && currentUser && !isOwnership);

  const needConfirm = currentUser && !hasOwnership;

  const handleApplyModalClick = () => {
    amplitude.sendEvent({
      id: '470',
      category: 'campaign',
      name: 'outreach_filled_details_and_applied',
      param: { campaign_id: campaignId },
    });
    modalDispatch({
      type: ModalTypes.TOGGLE_MODAL,
    });
    openDrawer('outreach-application-sent');
    amplitude.sendEvent({
      id: '475',
      category: 'campaign',
      name: 'outreach_download_app_page_viewed',
      param: { campaign_id: campaignId },
    });
  };

  const handleAcceptOfferModalClick = () => {
    amplitude.sendEvent({
      id: '469',
      category: 'campaign',
      name: 'outreach_apply_clicked_signed',
      param: { campaign_id: campaignId },
    });
    modalDispatch({
      type: ModalTypes.SET_MODAL,
      payload: {
        name: modalName.ACCEPT_OFFER,
        attach: {
          projectId,
          handleApplyModalClick,
        },
      },
    });
  };

  if (showAcceptActionOnly) {
    return (
      <>
        <ProjectAcceptActions
          offerId={offerId}
          currency={currency}
          needSignin={needSignin}
          needAuth={!currentUser}
          needLogout={needLogout}
          needConfirm={!!needConfirm}
          projectId={projectId}
          paidSocial={paidSocial}
          paidSocialBca={paidSocialBca}
          insightsAuthorized={insightsAuthorized}
          isBarter={isBarter}
          paidSocialEnabled={paidSocialEnabled}
          productDelivery={productDelivery}
          needIgInsights={
            campaignType === 'INFLUENCER' &&
            (campaignPlatform === 'FACEBOOK' || campaignPlatform === 'INSTAGRAM')
          }
          needTiktokShopAuthorization={
            campaign?.useCase === 'TIKTOK_SHOP' &&
            !queryProps.currentUser?.contractorProfile?.tiktokShopLinked &&
            campaign.organization?.tiktokShopLinked
          }
          handleAcceptOfferModalClick={handleAcceptOfferModalClick}
          showAcceptActionOnly={showAcceptActionOnly}
        />
        <OutreachApplicationSent campaignId={campaignId} brandName={brandName} />
      </>
    );
  }

  return (
    <>
      <div>
        {!isCampaignActive && <CampaignOutreachInactiveBanner />}
        <BriefContent
          campaign={campaign}
          currency={currency}
          isCreator={true}
          shortenView={shortenView}
        />
        {needAcceptance && !shortenView && (
          <ProjectAcceptActions
            offerId={offerId}
            currency={currency}
            needSignin={needSignin}
            needAuth={!currentUser}
            needLogout={needLogout}
            needConfirm={!!needConfirm}
            projectId={projectId}
            paidSocial={paidSocial}
            paidSocialBca={paidSocialBca}
            insightsAuthorized={insightsAuthorized}
            isBarter={isBarter}
            paidSocialEnabled={paidSocialEnabled}
            productDelivery={productDelivery}
            needIgInsights={
              campaignType === 'INFLUENCER' &&
              (campaignPlatform === 'FACEBOOK' || campaignPlatform === 'INSTAGRAM')
            }
            needTiktokShopAuthorization={
              campaign?.useCase === 'TIKTOK_SHOP' &&
              !queryProps.currentUser?.contractorProfile?.tiktokShopLinked &&
              campaign.organization?.tiktokShopLinked
            }
            handleAcceptOfferModalClick={handleAcceptOfferModalClick}
          />
        )}
      </div>
      <OutreachApplicationSent campaignId={campaignId} brandName={brandName} />
    </>
  );
};

export default ProjectForOffer;
