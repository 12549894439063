import React from 'react';
import { Link } from 'react-router-dom';

import styles from './BackButton.css';

import TextButton from 'Atoms/TextButton/TextButton';
import Icon from 'Components/ui/Icon/Icon';

interface Props {
  className?: string;
  msg?: string;
  msgValues?: {
    [key: string]: string | number | JSX.Element;
  };
  text?: string;
  link?: string;
  linkData?: object;
  withSearch?: boolean;
  onClick?: () => void;
}

const BackButton: React.FC<Props> = (props) => {
  const {
    className,
    link,
    linkData,
    onClick,
    msg = 'route.back',
    text,
    withSearch = false,
    msgValues,
  } = props;

  const data = {
    onClick,
    className: `${className} ${styles.root}`,
  };

  const button = (
    <TextButton
      msg={msg}
      text={text}
      color="normal"
      msgValues={msgValues}
      leftIcon={<Icon name="Arrow-big-left" />}
      className={styles.button}
      overflow
    />
  );

  const search = location.search;
  const searchData = withSearch ? { search } : {};

  return link ? (
    <Link {...data} to={{ pathname: link, ...searchData, ...linkData }}>
      {button}
    </Link>
  ) : (
    React.cloneElement(button, { ...data })
  );
};

export default BackButton;
