import React, { useContext, PropsWithChildren } from 'react';
import track, { useTracking } from 'react-tracking';
import { parse } from 'papaparse';
import compact from 'lodash/compact';

import styles from './UploadInput.pcss';

import importCreators from 'Mutations/ImportCreators.Mutation';
import { UploadMediaplanQuery$data } from 'GraphTypes/UploadMediaplanQuery.graphql';
import UploadMediaplanFail from 'Modal/advertiser/UploadMediaplanFail/UploadMediaplanFail';
import DropDown from 'Molecules/DropDown/DropDown';
import FileUploader from 'Atoms/FileUploader/FileUploader';
import TextButton from 'Atoms/TextButton/TextButton';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { INSTAGRAM, TIKTOK, FACEBOOK } from 'Constants/general';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

interface Props {
  platform: string;
  onClick: (platform: string) => void;
  onChange?: (files: FileList | null) => void;
}

interface UploadInputProps {
  className?: string;
  campaignId: string;
  withPartnership?: boolean;
  campaignPlatform?: string | null;
  campaign: UploadMediaplanQuery$data['campaign'];
  canImportMediaplan?: boolean;
  disabled?: boolean;
}

let platform: any;

const UploadItem: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, onChange, platform, onClick } = props;

  const handleClick = () => onClick(platform);

  return (
    <div className={styles.upload} onClick={handleClick}>
      <div className={styles.input}>
        <FileUploader typeList=".csv" onChange={onChange} className={styles.uploader} />
      </div>
      {children}
    </div>
  );
};

const UploadInput: React.FC<UploadInputProps> = (props) => {
  const { className, campaignId, canImportMediaplan, campaignPlatform, withPartnership } = props;

  platform = '';

  const { openDrawer } = useContext(DrawerContext);

  const canLoad = true;

  const tracking = useTracking();

  const handleFileChange = (files: FileList | null) => {
    tracking.trackEvent({ event: 'file_selected' });

    if (!files?.length) return;

    parse(files[0], {
      delimiter: 'tab',
      complete: handleParseComplete,
    });
  };

  const handleParseComplete = ({ data, errors }: any) => {
    tracking.trackEvent({
      event: 'file_parse_completed',
      payload: { errors: errors.length !== 0 || data.length === 0 },
    });

    if (errors.length !== 0 || data.length === 0) {
      openDrawer('upload_csv_failed');
    }

    const usernames = handleParseData(data);

    if (usernames && platform) {
      importCreators({ usernames, campaignId, platform });
    }
  };

  const handleParseData = (data: any) => {
    const usernames = [];

    for (let i = 0; i < data.length; i++) {
      const item = data[i][0];

      if (data[i][0].split(',').length > 1) {
        openDrawer('upload_csv_failed');
        break;
      }

      if (item) {
        usernames.push(item);
      }
    }

    if (usernames.length === 0) {
      openDrawer('upload_csv_failed');

      return null;
    }

    return usernames;
  };

  const handlePlatformChange = (newplatform: string) => {
    platform = newplatform;
  };

  const label = (
    <TextButton
      disabled={!canLoad}
      msg="mediaplan.upload_mediaplan"
      data-test="uploadInput:textButton:uploadMediaplan"
    />
  );

  if (!canLoad) {
    return (
      <div className={`${className} ${styles.root}`}>
        <Tooltip
          id="upload"
          place="bottom"
          tooltipMsg={canImportMediaplan ? undefined : 'tooltip.limit_avaiable'}
        >
          {label}
        </Tooltip>
      </div>
    );
  }

  const list = compact([
    (withPartnership && (campaignPlatform === INSTAGRAM || campaignPlatform === FACEBOOK)) ||
    !withPartnership
      ? {
          id: INSTAGRAM,
          textData: {
            textData: {
              msg: 'mediaplan.uploading.creators_list',
              transform: 'capitalize',
              values: { type: 'Instagram' },
            },
          },
          wrap: (
            <UploadItem
              platform={INSTAGRAM}
              onClick={handlePlatformChange}
              onChange={handleFileChange}
            />
          ),
        }
      : null,
    (withPartnership && campaignPlatform === TIKTOK) || !withPartnership
      ? {
          id: TIKTOK,
          textData: {
            textData: {
              msg: 'mediaplan.uploading.creators_list',
              transform: 'capitalize',
              values: { type: 'Tiktok' },
            },
          },
          wrap: (
            <UploadItem
              platform={TIKTOK}
              onClick={handlePlatformChange}
              onChange={handleFileChange}
            />
          ),
        }
      : null,
  ]);

  return (
    <>
      <DropDown position="bottomRight" anchor={label} list={list} />
      <UploadMediaplanFail />
    </>
  );
};

export default track({
  element: 'import_creator',
})(UploadInput);
