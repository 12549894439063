import React, { MouseEvent } from 'react';
import { graphql, useFragment } from 'react-relay';
import classnames from 'classnames';

import PostsSection from '../PostsSection/PostsSection';
import Post from '../Post/Post';

import styles from './InsightsStoriesMedias.css';

import { InsightsStoriesMedias_creator$key } from 'GraphTypes/InsightsStoriesMedias_creator.graphql';

interface Props {
  isBlockedView?: boolean;
  creator: InsightsStoriesMedias_creator$key;
  onUnlockBannerShow: (e: MouseEvent<HTMLDivElement>) => void;
}

const InsightsStoriesMedias: React.FC<Props> = (props) => {
  const { isBlockedView, creator, onUnlockBannerShow } = props;

  const data = useFragment(
    graphql`
      fragment InsightsStoriesMedias_creator on Creator {
        ... on InstagramCreator {
          user {
            followedByCount
          }
          stories: insightsMedias(placements: [STORY], first: 30) {
            edges {
              node {
                id
                engagement
                mediaUrl
                thumbnailUrl
                type
                caption
                impressions
                reach
                placement
                finalStoryInsightsCollected
                file {
                  thumbnailUrl
                  secureUrl
                }
              }
            }
          }
        }
      }
    `,
    creator
  );
  const list = data.stories?.edges;

  if (list?.length === 0) return null;

  return (
    <PostsSection title="creator_statistic.subtitles.stories" isBlockedView={isBlockedView}>
      <div onClick={isBlockedView ? onUnlockBannerShow : undefined}>
        <div className={classnames(styles.content, { [styles.disabled]: isBlockedView })}>
          {list?.map((item) => {
            if (!item?.node) return null;

            const {
              id,
              type,
              caption,
              impressions,
              reach,
              file,
              placement,
              finalStoryInsightsCollected,
            } = item.node;

            if (!file) return null;

            const videoUrl = type === 'VIDEO' ? file.secureUrl : null;
            const thumbnailUrl = file.thumbnailUrl;

            const isStatBlocked = placement === 'STORY' && !finalStoryInsightsCollected;

            return (
              <Post
                key={id}
                id={id}
                mediaType={type}
                isStatBlocked={isStatBlocked}
                thumbnailUrl={thumbnailUrl}
                caption={caption}
                reach={reach}
                impressions={impressions}
                isStories={true}
                videoUrl={videoUrl}
                finalVideoUrl={videoUrl}
              />
            );
          })}
        </div>
      </div>
    </PostsSection>
  );
};

export default InsightsStoriesMedias;
