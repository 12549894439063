import React, { Suspense, useCallback, useContext, useState } from 'react';
import { QueryRenderer, graphql, useLazyLoadQuery } from 'react-relay';
import classnames from 'classnames';

import styles from './BcaActivation.pcss';

import { amplitude } from 'Helpers/amplitude';
import {
  BcaActivationQuery as QueryType,
  BcaPermissionState,
} from 'GraphTypes/BcaActivationQuery.graphql';
import BcaRequestPermissions from 'Modal/advertiser/BcaRequestPermissions/BcaRequestPermissions';
import MaxLicensedCreatorsExceededDrawer from 'Modal/advertiser/MaxLicensedCreatorsExceeded/MaxLicensedCreatorsExceeded';
import environment from 'Api/Environment';
import Text from 'Components/ui/Text/Text';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import Button from 'Atoms/Button/Button';
import Image from 'Components/ui/Image/Image';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import ActivateBca from 'Molecules/ActivateBca/ActivateBca';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { ProjectInfoContext } from 'Page/common/Chat/Dialog/ProjectInfo/ProjectInfo.Context';
import { BcaPermissionStateEnum } from 'Types/enums';
import type { EventData } from 'Helpers/amplitude.types';
import { RequestBcaPermissionMutation$data } from 'GraphTypes/RequestBcaPermissionMutation.graphql';
import paidAdsConnectedImg from 'Images/icons/paid-ads-connected.svg';
import paidAdsErrorImg from 'Images/icons/paid-ads-error.svg';
import paidAdsPendingImg from 'Images/icons/paid-ads-pending.svg';
import { useRevokeBcaPermissionMutation } from 'Mutations/RevokeBcaPermission.Mutation';
import BcaPermissionModal from 'Modal/advertiser/BcaPermissionModal/BcaPermissionModal';

const BcaActivationQuery = graphql`
  query BcaActivationQuery($projectId: ID!) {
    project(id: $projectId) {
      id
      paidSocial
      campaign {
        id
      }
      creator {
        id
        ... on InstagramCreator {
          user {
            instagramBusinessAccountId
          }
        }
      }
      bcaRequest {
        permission {
          id
          state
        }
      }
      launch {
        id
      }
    }
    currentUser {
      admin
      organization {
        subscription {
          canUsePaidSocial
        }
      }
    }
  }
`;

interface Props {
  className?: string;
  projectId: string;
  advertiserStage?: string | null;
  simpleView?: boolean;
}

const BcaActivation: React.FC<Props> = (props) => {
  const { projectId, className, simpleView, advertiserStage } = props;
  const { openDrawer } = useContext(DrawerContext);
  const [revokeBcaPermission, isLoading] = useRevokeBcaPermissionMutation();
  const [bcaRevokeError, setBcaRevokeError] = useState<string>('');

  const {
    isMaxBcaConnectionsExceeded,
    subscription: { maxBcaConnections, planId },
  } = useContext(ProjectInfoContext);

  const [hasBcaError, setHasBcaError] = useState(false);

  const handleCheckingConnectionPossibility = useCallback(() => {
    if (isMaxBcaConnectionsExceeded) {
      openDrawer('max-licensed-creators-exceeded', undefined, simpleView ? true : undefined);
      return false;
    }

    return true;
  }, [isMaxBcaConnectionsExceeded, maxBcaConnections, planId]);

  const handleBcaRequestPermissionDone = (data?: RequestBcaPermissionMutation$data) => {
    const permissionState = data?.requestBcaPermission?.bcaRequest?.permission?.state;
    setHasBcaError(false);
  };

  const handleBcaRequestPermissionError = () => {
    setHasBcaError(true);
  };

  const isProd = process.env.DEPLOY_ENV === 'production';

  const queryProps = useLazyLoadQuery<QueryType>(
    BcaActivationQuery,
    { projectId },
    { fetchPolicy: 'store-and-network' }
  );

  if (!queryProps) return null;

  const project = queryProps.project;
  const launch = project?.launch?.id;

  const bcaPermissionState = project?.bcaRequest?.permission?.state;

  const canUsePaidSocial = queryProps.currentUser?.organization?.subscription?.canUsePaidSocial;
  const isAdmin = queryProps?.currentUser?.admin;
  if (!launch || !canUsePaidSocial) return null;

  const instagramBusinessAccountId = project?.creator?.user?.instagramBusinessAccountId;
  const isBcaUnknownStatus = bcaPermissionState === BcaPermissionStateEnum.UNKNOWN;

  const statusApproved = bcaPermissionState === BcaPermissionStateEnum.APPROVED;
  const statusPending = bcaPermissionState === BcaPermissionStateEnum.PENDING_APPROVAL;

  const isButtonDisabled = (!instagramBusinessAccountId && !isBcaUnknownStatus) || isLoading;

  const bcaPermissionId = project?.bcaRequest?.permission?.id;

  const buttonMessages: ButtonMessagesMapping = {
    undefined: 'project.bca.ads_manager.activate',
    [BcaPermissionStateEnum.APPROVED]: 'project.bca.ads_manager.revoke_connection',
    [BcaPermissionStateEnum.PENDING_APPROVAL]: 'project.bca.ads_manager.revoke_connection',
    [BcaPermissionStateEnum.CANCELED]: 'project.bca.ads_manager.try_again',
    [BcaPermissionStateEnum.REVOKED]: 'project.bca.ads_manager.try_again',
    [BcaPermissionStateEnum.EXPIRED]: 'project.bca.ads_manager.try_again',
    [BcaPermissionStateEnum.UNKNOWN]: 'project.bca.ads_manager.try_again',
    [BcaPermissionStateEnum.INVALID]: 'project.bca.ads_manager.try_again',
  };

  const createActivateBtn = (children: JSX.Element) => {
    return (
      <ActivateBca
        simpleView={simpleView}
        disabled={isButtonDisabled}
        projectId={projectId}
        onBcaRequestPermissionDone={handleBcaRequestPermissionDone}
        onBcaRequestPermissionError={handleBcaRequestPermissionError}
        onCheckingConnectionPossibility={handleCheckingConnectionPossibility}
      >
        {children}
      </ActivateBca>
    );
  };

  const handleButtonClick = () => {
    setHasBcaError(false);

    const eventData = {
      144: {
        id: 144,
        name: 'creator_licensing_connection',
      },
      145: {
        id: 145,
        name: 'creator_licensing_connection_try_again',
      },
      386: {
        id: 386,
        name: 'partnership_ads_revoke_click',
        param: {
          connected: bcaPermissionState === BcaPermissionStateEnum.APPROVED,
          pending: bcaPermissionState === BcaPermissionStateEnum.PENDING_APPROVAL,
        },
      },
      387: {
        id: 387,
        name: 'partnership_ads_revoke_failure',
        category: 'chat',
        param: {
          connected: bcaPermissionState === BcaPermissionStateEnum.APPROVED,
          pending: bcaPermissionState === BcaPermissionStateEnum.PENDING_APPROVAL,
        },
      },
    };

    const buttonAmplitudeEventsMapping: AmplitudeEventMapping = {
      [BcaPermissionStateEnum.APPROVED]: eventData[386],
      [BcaPermissionStateEnum.PENDING_APPROVAL]: eventData[386],
      undefined: eventData[144],
      [BcaPermissionStateEnum.CANCELED]: eventData[145],
      [BcaPermissionStateEnum.REVOKED]: eventData[145],
      [BcaPermissionStateEnum.EXPIRED]: eventData[145],
      [BcaPermissionStateEnum.UNKNOWN]: eventData[145],
      [BcaPermissionStateEnum.INVALID]: eventData[145],
    };
    const amplitudeEventData = buttonAmplitudeEventsMapping[`${bcaPermissionState}`];

    if (amplitudeEventData) {
      amplitude.sendEvent({
        id: amplitudeEventData.id,
        name: amplitudeEventData.name,
        category: 'chat',
        param: { ...amplitudeEventData?.param },
      });
    }
    if (!isProd && (statusPending || statusApproved)) {
      revokeBcaPermission({
        variables: { input: { projectId: projectId } },
        onCompleted: (response, errors) => {
          if (errors && errors.length > 0) {
            setBcaRevokeError('general.something_went_wrong');
            return;
          }
          setBcaRevokeError('');
        },
        onError: () => {
          amplitude.sendEvent(eventData[387]);
        },
      });
    }
    handleBcaInfoShow();
  };

  let psStatus = <Image src={paidAdsConnectedImg} size={24} />;
  let tooltip =
    simpleView && bcaPermissionState
      ? `tooltip.fb_ads.${bcaPermissionState.toLowerCase()}`
      : 'tooltip.fb_ads_unavailable';

  if (
    bcaPermissionState === 'CANCELED' ||
    bcaPermissionState === 'INVALID' ||
    bcaPermissionState === 'REVOKED' ||
    bcaPermissionState === 'UNKNOWN' ||
    bcaPermissionState === 'EXPIRED'
  ) {
    psStatus = <Image src={paidAdsErrorImg} size={24} />;
  } else if (bcaPermissionState === 'PENDING_APPROVAL') {
    psStatus = <Image src={paidAdsPendingImg} size={24} />;
  } else if (!bcaPermissionState) {
    tooltip = instagramBusinessAccountId ? 'tooltip.fb_ads.start' : 'tooltip.fb_ads_unavailable';
  }

  const handleBcaInfoShow = () => {
    openDrawer(`bca-permission`, undefined, true);
  };
  const btn = simpleView ? (
    <AlterButton
      type={simpleView && advertiserStage === 'COMPLETED' ? 'white' : 'grey'}
      className={styles.inDropdown}
      msg="chat.in_modal.action.ads"
      icon={!bcaPermissionState ? 'Paid-ads' : undefined}
      leftElement={bcaPermissionState ? psStatus : undefined}
      disabled={!instagramBusinessAccountId && !bcaPermissionState}
      onClick={bcaPermissionState ? handleBcaInfoShow : undefined}
      data-test="bcaActivation:alterButton:ads"
    />
  ) : (
    <Button
      width="full"
      color={!statusApproved ? 'secondary' : 'normal'}
      disabled={isButtonDisabled}
      onClick={handleButtonClick}
      className={styles.bcaButton}
      msg={buttonMessages[`${bcaPermissionState}`]}
      data-test="bcaActivation:button:bcaButton"
    />
  );
  const btnWrap =
    instagramBusinessAccountId && !bcaPermissionState ? (
      createActivateBtn(btn)
    ) : (
      <Tooltip
        id="connectAds"
        tooltipMsg={!simpleView && !statusApproved && !statusPending ? tooltip : undefined}
        place="top"
      >
        {btn}
      </Tooltip>
    );

  if (simpleView) {
    return (
      <Tooltip
        id="connectAds"
        tooltipMsg={tooltip}
        place="top"
        className={classnames(
          simpleView && advertiserStage === 'COMPLETED' ? styles.inDropdown : undefined
        )}
      >
        {btnWrap}
      </Tooltip>
    );
  }

  return (
    <div className={className}>
      <header
        className={classnames(styles.header, {
          [styles.nomargin]: statusApproved,
        })}
      >
        <Text
          msg="project.bca.ads_manager.title"
          className={styles.title}
          data-test="bcaActivation:text:title"
        />
        {bcaPermissionState && (
          <Text
            type="s"
            msg={`project.bca.ads_manager.bca_permission_state.${bcaPermissionState.toLowerCase()}`}
            className={classnames(styles.bcaPermissionState, {
              [styles.sky]: bcaPermissionState === BcaPermissionStateEnum.PENDING_APPROVAL,
              [styles.green]: bcaPermissionState === BcaPermissionStateEnum.APPROVED,
            })}
            data-test="bcaActivation:text:unknown"
          />
        )}
      </header>
      {(hasBcaError || isBcaUnknownStatus || bcaRevokeError) && (
        <Text
          type="sm"
          msg={bcaRevokeError || 'project.bca.ads_manager.something_went_wrong'}
          className={classnames(styles.bcaErrorText, {
            [styles.revokeError]: !!bcaRevokeError,
          })}
          data-test="bcaActivation:text:unknown"
        />
      )}
      {((!statusApproved && !statusPending) || !isProd) && btnWrap}
      {!simpleView && (
        <MaxLicensedCreatorsExceededDrawer
          attach={{
            planId,
            maxLicensedCreators: maxBcaConnections,
          }}
        />
      )}
      {!simpleView && (
        <Suspense fallback={<div />}>
          <BcaRequestPermissions
            projectId={projectId}
            onDone={handleBcaRequestPermissionDone}
            onError={handleBcaRequestPermissionError}
          />
        </Suspense>
      )}
      <BcaPermissionModal projectId={projectId} drawerProps={{ rootKey: `bca-permission` }} />
    </div>
  );
};

export default BcaActivation;

type ButtonMessagesMapping = {
  [K in BcaPermissionStateEnum | 'undefined']: string;
};

type AmplitudeEventMapping = {
  [K in BcaPermissionStateEnum | 'undefined']: Pick<EventData, 'id' | 'name' | 'param'> | null;
};
