import React, { useState, useCallback, useMemo, useContext, forwardRef } from 'react';
import cn from 'classnames';
import { useLazyLoadQuery } from 'react-relay';

import styles from './CustomListSelection.pcss';
import {
  CustomListSelectionQuery,
  CustomListSelectionQueryType,
} from './CustomListSelection.Query';

import { CustomListDropdown } from 'Components/CustomListDropdown';
import IconToggler from 'Components/IconToggler/IconToggler';
import addCreatorToFavorites, {
  AddCreatorToFavoritesMutation,
} from 'Mutations/AddCreatorToFavorites.Mutation';
import removeCreatorFromFavorites, {
  RemoveCreatorFromFavoritesMutation,
} from 'Mutations/RemoveCreatorFromFavorites.Mutation';
import { useAddCreatorToCustomListMutation } from 'Mutations/AddCreatorToCustomList.Mutation';
import { useRemoveCreatorFromCustomListMutation } from 'Mutations/RemoveCreatorFromCustomList.Mutation';
import { promisifyMutation } from 'Util/promisifyMutation';
import CustomListCreateDrawer from 'Modal/advertiser/CustomListCreate/CustomListCreateDrawer';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import type { CreateCustomListMutation$data } from 'GraphTypes/CreateCustomListMutation.graphql';

type Props = {
  organizationId?: string;
  creatorId: string;
  creatorCustomLists?: string[];
  className?: string;
  isFavoriteCreator?: boolean;
  iconTogglerProps?: Partial<Pick<IconTogglerProps, 'kind' | 'size'>>;
  dropdownProps?: Partial<
    Pick<
      NonNullable<CustomListDropdownProps['dropdownProps']>,
      'openedByDefault' | 'closeBySelect' | 'className' | 'onOpen' | 'onClose' | 'openKey'
    >
  >;
  classes?: {
    dropdownGroup?: string;
  };
  onFavoriteValueChange?: (isFavorite: boolean) => void;
  onAddCustomListButtonClick?: () => void;
  onCustomListSuccessfulCreated?: (response: CreateCustomListMutation$data) => void;
  onAddCreatorToCustomList?: (customListItem: Omit<CustomListItem, 'active'>) => void;
  onRemoveCreatorFromCustomList?: (customListItem: Omit<CustomListItem, 'active'>) => void;
};

const CustomListSelection = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    className,
    creatorId,
    creatorCustomLists = [],
    classes,
    organizationId = '',
    iconTogglerProps,
    isFavoriteCreator = false,
    dropdownProps,
    onFavoriteValueChange,
    onAddCustomListButtonClick,
    onCustomListSuccessfulCreated,
    onRemoveCreatorFromCustomList,
    onAddCreatorToCustomList,
  } = props;
  const { openDrawer } = useContext(DrawerContext);
  const [isFavoritesLoading, setIsFavoritesLoading] = useState<boolean>(false);
  const [isCustomListLoading, setIsCustomListLoading] = useState<boolean>(false);
  const [addCreatorToCustomList] = useAddCreatorToCustomListMutation(organizationId);
  const [removeCreatorFromCustomList] = useRemoveCreatorFromCustomListMutation(organizationId);

  const data = useLazyLoadQuery<CustomListSelectionQueryType>(CustomListSelectionQuery, {
    organizationId,
  });

  const customListItems = useMemo<CustomListItem[]>(() => {
    const edges = data.organization?.customLists?.edges || [];
    return edges.map<CustomListItem>((item) => {
      const id = item?.node?.id || '';
      const isActive = creatorCustomLists.includes(id);
      return {
        id: item?.node?.id || '',
        name: item?.node?.name || '',
        active: isActive,
      };
    });
  }, [data, creatorId, creatorCustomLists]);

  const isTurnedOn = useMemo<boolean>(() => {
    return isFavoriteCreator || customListItems.some((item) => item.active);
  }, [isFavoriteCreator, customListItems]);

  const handleToggleFavorites = useCallback(() => {
    if (isFavoritesLoading) return;
    setIsFavoritesLoading(true);
    if (isFavoriteCreator) {
      const mutation = promisifyMutation<RemoveCreatorFromFavoritesMutation>(
        removeCreatorFromFavorites
      );
      mutation({ creatorId, organizationId }).finally(() => {
        setIsFavoritesLoading(false);
      });
      onFavoriteValueChange?.(false);
    } else {
      const mutation = promisifyMutation<AddCreatorToFavoritesMutation>(addCreatorToFavorites);
      mutation({ creatorId, organizationId }).finally(() => {
        setIsFavoritesLoading(false);
      });
      onFavoriteValueChange?.(true);
    }
  }, [isFavoriteCreator, creatorId, isFavoritesLoading, organizationId, onFavoriteValueChange]);

  const handleCustomListItemClick = useCallback<HandleCustomListItemClick>(
    (customListItem) => {
      if (isCustomListLoading) return;
      setIsCustomListLoading(true);
      if (customListItem.active) {
        removeCreatorFromCustomList({
          variables: {
            input: {
              creatorId,
              customListId: customListItem.id,
              organizationId,
            },
          },
          onCompleted: () => {
            onRemoveCreatorFromCustomList?.({ id: customListItem.id, name: customListItem.name });
            setIsCustomListLoading(false);
          },
          onError: () => {
            setIsCustomListLoading(false);
          },
        });
      } else {
        addCreatorToCustomList({
          variables: {
            input: { creatorId, customListId: customListItem.id },
          },
          onCompleted: () => {
            onAddCreatorToCustomList?.({ id: customListItem.id, name: customListItem.name });
            setIsCustomListLoading(false);
          },
          onError: () => {
            setIsCustomListLoading(false);
          },
        });
      }
    },
    [
      isCustomListLoading,
      organizationId,
      creatorId,
      onAddCreatorToCustomList,
      onRemoveCreatorFromCustomList,
    ]
  );

  const handleAddCustomListButtonClick = useCallback(() => {
    openDrawer('custom-list-create');
    onAddCustomListButtonClick?.();
  }, [onAddCustomListButtonClick]);

  return (
    <>
      <div className={cn(styles.root, className)} ref={ref}>
        <CustomListDropdown
          items={customListItems}
          isFavoriteCreator={isFavoriteCreator}
          onFavoriteButtonClick={handleToggleFavorites}
          onCustomListItemClick={handleCustomListItemClick}
          onAddCustomListButtonClick={handleAddCustomListButtonClick}
          classes={{
            dropdownGroup: cn(styles.dropdownGroup, classes?.dropdownGroup),
          }}
          dropdownProps={{
            closeBySelect: false,
            ...dropdownProps,
            className: cn(styles.dropdownRoot, dropdownProps?.className),
            value: (
              <button type="button" className={styles.anchorButton}>
                <IconToggler
                  kind="custom-list-white-filled"
                  size={24}
                  {...iconTogglerProps}
                  className={styles.iconToggler}
                  active={isTurnedOn}
                />
              </button>
            ),
          }}
        />
      </div>
      <CustomListCreateDrawer
        organizationId={organizationId}
        onCustomListSuccessfulCreated={onCustomListSuccessfulCreated}
      />
    </>
  );
});

export default CustomListSelection;

// types

type CustomListDropdownProps = React.ComponentProps<typeof CustomListDropdown>;
type CustomListItem = NonNullable<CustomListDropdownProps['items']>[0];
type HandleCustomListItemClick = NonNullable<CustomListDropdownProps['onCustomListItemClick']>;
type IconTogglerProps = React.ComponentProps<typeof IconToggler>;

export type { Props as CustomListSelectionProps };
