import React, { useState, useCallback, Suspense, useContext, useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import track, { useTracking } from 'react-tracking';
import classnames from 'classnames';
import compact from 'lodash/compact';

import parentStyles from '../../../List.pcss';

import styles from './SearchResultControls.pcss';

import CustomListSelection, {
  CustomListSelectionProps,
} from 'Organisms/CustomListSelection/CustomListSelection';
import { getCreatorStatLink } from 'Util/links';
import markCreatorSearchResultAsViewed from 'Mutations/MarkCreatorSearchResultAsViewed.Mutation';
import createProject from 'Mutations/CreateProject.Mutation';
import archiveProject from 'Api/mutations/ArchiveProject.Mutation';
import unarchiveProject from 'Mutations/UnarchiveProject.Mutation';
import { SearchResultControls_result$key } from 'GraphTypes/SearchResultControls_result.graphql';
import { CreatorType } from 'GraphTypes/SearchResultsContainerQuery.graphql';
import Icon from 'Atoms/Icon/Icon';
import icons from 'Icons';
import Button from 'Atoms/Button/Button';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import Spinner from 'Atoms/Spinner/Spinner';
import TextButton from 'Atoms/TextButton/TextButton';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { amplitude } from 'Helpers/amplitude';
import {
  CreatorsSearchContext,
  Types,
} from 'AdvertiserPage/Mediaplan/SearchSection/CreatorsSearch.Context';
import {
  NotificationsContext,
  Types as NotificationsTypes,
} from 'Containers/Notifications/Notifications.Context';

interface StatusBtnProps {
  clickHandler?: () => void;
  btnClassName?: string;
  iconClassName?: string;
  iconName?: keyof typeof icons;
  tooltipId?: string;
  tooltipMsg?: string;
}

interface Props {
  result: SearchResultControls_result$key;
  campaignId: string;
  isHidden: boolean;
  creatorType?: CreatorType | null;
  loading: boolean;
  isAdmin: boolean;
  organizationId?: string;
}

const SearchResultControls: React.FC<Props> = (props) => {
  const { result, campaignId, isHidden, isAdmin, organizationId = '' } = props;
  const [loading, setLoading] = useState(false);

  const { dispatch: creatorsSearchDispatch } = useContext(CreatorsSearchContext);
  const { dispatch: notificationsDispatch } = useContext(NotificationsContext);

  const data = useFragment(
    graphql`
      fragment SearchResultControls_result on CreatorSearchResult {
        viewed
        favorite
        project {
          id
          archivation(side: ADVERTISER) {
            id
          }
          offer {
            id
            acceptance {
              id
            }
          }
          launch {
            id
          }
          invitedByOrganizationMember
        }
        creator {
          id
          username
          customLists {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    `,
    result
  );

  const viewed = data?.viewed;
  const creatorId = data?.creator?.id;
  const creatorCustomLists = useMemo(() => {
    return compact(data?.creator?.customLists?.edges?.map((item) => item?.node?.id)) || [];
  }, [data?.creator?.customLists?.edges]);
  const username = data?.creator?.username || '';
  const project = data?.project;
  const projectId = project?.id;
  const archivation = project?.archivation;
  const hasAcceptance = project?.offer && project.offer.acceptance;
  const isOnboarded = hasAcceptance && project?.launch;
  const invitedByOrganizationMember = project?.invitedByOrganizationMember;

  const tracking = useTracking();

  const showCreatedByAdmin = !!isAdmin;

  const notificationText = username
    ? 'browse_creators.notifications.hide'
    : 'browse_creators.notifications.text_default';

  if (!creatorId) return null;

  const setCreatorViewed = () => {
    if (!viewed) markCreatorSearchResultAsViewed({ campaignId, creatorId });
  };

  const projectCreationDone = () => setLoading(false);
  const projectArchivationDone = () => setLoading(false);

  const handleAddClick = () => {
    tracking.trackEvent({
      event: 'clicked',
      payload: {
        campaignId,
        creatorId,
      },
    });

    setLoading(true);

    setCreatorViewed();

    if (!archivation) {
      return createProject(
        { campaignId, creatorId, createdByAdmin: showCreatedByAdmin, organizationId },
        projectCreationDone
      );
    } // Return statement is important here

    if (projectId) unarchiveProject({ projectId, campaignId }, projectCreationDone);
  };

  const handleArchiveProject = () => {
    setLoading(true);

    amplitude.sendEvent({
      id: 22,
      category: 'campaign',
      name: 'archive_creator',
      param: {
        admin: isAdmin,
        source: 'MEDIAPLAN',
        actionName: 'archive',
        campaign_id: campaignId,
      },
    });

    if (projectId) archiveProject({ projectId }, projectArchivationDone);
  };

  const handleProfileClick = () => setCreatorViewed();

  const handleHideClick = () => {
    creatorsSearchDispatch({
      type: Types.ADD_CREATOR_FOR_EXLUSION,
      payload: { creatorId, username },
    });

    notificationsDispatch({
      type: NotificationsTypes.ADD_NOTIFICATION,
      payload: {
        item: {
          id: creatorId,
          text: notificationText,
          values: { username },
          callback: () => {
            creatorsSearchDispatch({
              type: Types.REMOVE_CREATOR_FOR_EXLUSION,
              payload: { creatorId },
            });
          },
        },
      },
    });
  };

  const currentCampaignId = campaignId;
  const link =
    currentCampaignId && creatorId
      ? getCreatorStatLink({ campaignId: currentCampaignId, creatorId })
      : '';
  const rootStyles =
    isHidden || loading || !!(project && (invitedByOrganizationMember || isAdmin))
      ? styles.active
      : '';

  const renderStatusBtn = () => {
    const isAddBtn =
      (!project ||
        (project && archivation) ||
        (project && !invitedByOrganizationMember && !isAdmin)) &&
      !isHidden;
    const isRemoveBtn =
      project &&
      !archivation &&
      !isOnboarded &&
      (invitedByOrganizationMember || (!invitedByOrganizationMember && isAdmin));

    if (loading || (!isAddBtn && !isRemoveBtn)) return null;

    const btnAttr: StatusBtnProps = {}; // Configuration for rendering status btn

    btnAttr.clickHandler = handleAddClick;
    btnAttr.btnClassName = classnames(styles.statusIcon, styles.add);
    btnAttr.iconName = 'add';
    btnAttr.tooltipId = `addToMediaplan_${creatorId}`;
    btnAttr.tooltipMsg = 'creator_card.mediaplan.add';

    if (isRemoveBtn) {
      btnAttr.clickHandler = handleArchiveProject;
      btnAttr.btnClassName = classnames(styles.statusIcon, { [styles.onboarded]: isOnboarded });
      btnAttr.iconName = 'addedColored';
      btnAttr.tooltipId = `removeFromMediaplan_${creatorId}`;
      btnAttr.tooltipMsg = 'creator_card.mediaplan.remove';
    }

    return (
      <div onClick={btnAttr.clickHandler} className={btnAttr.btnClassName}>
        <Tooltip id={btnAttr.tooltipId} tooltipMsg={btnAttr.tooltipMsg} place="top">
          <Icon name={btnAttr.iconName} />
        </Tooltip>
      </div>
    );
  };

  const handleCustomListSuccessfulCreated = useCallback<OnCustomListSuccessfulCreated>(
    (response) => {
      if (response.createCustomList?.__typename === 'CreateCustomListPayload') {
        const listName = response.createCustomList.customList?.name || '';
        amplitude.sendEvent<289>({
          id: '289',
          category: 'marketplace',
          name: 'list_created',
          param: {
            list_name: listName,
          },
        });
      }
    },
    []
  );

  const handleAddCustomListButtonClick = useCallback(() => {
    amplitude.sendEvent<288>({
      id: '288',
      category: 'marketplace',
      name: 'new_list_click',
      param: undefined,
    });
  }, []);

  const handleAddCreatorToCustomList = useCallback<OnAddCreatorToCustomList>(
    (customListItem) => {
      amplitude.sendEvent<290>({
        id: '290',
        category: 'marketplace',
        name: 'creator_added_lists',
        param: {
          creator_id: creatorId,
          list_name: customListItem.name,
        },
      });
    },
    [creatorId]
  );

  const handleRemoveCreatorFromCustomList = useCallback<OnRemoveCreatorFromCustomList>(
    (customListItem) => {
      amplitude.sendEvent<291>({
        id: '291',
        category: 'marketplace',
        name: 'creator_removed_lists',
        param: {
          creator_id: creatorId,
          list_name: customListItem.name,
        },
      });
    },
    [creatorId]
  );

  const handleFavoriteValueChange = useCallback<OnFavoriteValueChange>(
    (isFavorite) => {
      if (isFavorite) {
        amplitude.sendEvent<271>({
          id: '271',
          category: 'marketplace',
          name: 'creator_added_favorites',
          param: {
            creator_id: creatorId,
          },
        });
      } else {
        amplitude.sendEvent<272>({
          id: '272',
          category: 'marketplace',
          name: 'creator_removed_favorites',
          param: {
            creator_id: creatorId,
          },
        });
      }
    },
    [creatorId]
  );

  return (
    <div className={`${styles.root} ${rootStyles}`}>
      <div className={styles.back} />
      {isAdmin && (
        <Tooltip
          id={`hide_eye_${creatorId}`}
          tooltipMsg="browse_creators.creator.stop_showing.tooltip"
          className={styles.hideBtn}
          place="bottom"
        >
          <TextButton
            color="normal"
            theme="dark"
            leftIconName="hideEye"
            msg="browse_creators.creator.stop_showing"
            onClick={handleHideClick}
            data-test="searchResultControls:textButton:stopShowing"
          />
        </Tooltip>
      )}
      <div className={styles.actions}>
        <div className={styles.favorites}>
          <Tooltip
            id="browse_creators_favorites"
            tooltipMsg="browse_creators.edit_lists"
            place="top"
          >
            <Suspense fallback={<Spinner size="24" />}>
              <CustomListSelection
                creatorId={creatorId}
                creatorCustomLists={creatorCustomLists}
                organizationId={organizationId}
                isFavoriteCreator={data?.favorite === true}
                classes={{ dropdownGroup: parentStyles.customListDropdownGroup }}
                iconTogglerProps={{
                  size: 28,
                }}
                dropdownProps={{
                  onOpen: () => {
                    amplitude.sendEvent<287>({
                      id: '287',
                      category: 'marketplace',
                      name: 'list_icon_click',
                      param: undefined,
                    });
                  },
                }}
                onFavoriteValueChange={handleFavoriteValueChange}
                onCustomListSuccessfulCreated={handleCustomListSuccessfulCreated}
                onAddCustomListButtonClick={handleAddCustomListButtonClick}
                onAddCreatorToCustomList={handleAddCreatorToCustomList}
                onRemoveCreatorFromCustomList={handleRemoveCreatorFromCustomList}
              />
            </Suspense>
          </Tooltip>
        </div>
        {renderStatusBtn()}
        {loading && <Spinner size="sm" color="white" className={styles.statusIcon} />}
      </div>
      <div onClick={handleProfileClick}>
        <SmartLink path={link} target="_blank" className={styles.link}>
          <Button
            color="normal"
            theme="dark"
            msg="browse_creators.mediaplan_view_profile"
            data-test="searchResultControls:button:mediaplanViewProfile"
          />
        </SmartLink>
      </div>
    </div>
  );
};

const SearchResultControlsWrap = track({
  element: 'add_to_mediaplan_icon',
})(SearchResultControls);

export default SearchResultControlsWrap;

// types

type OnCustomListSuccessfulCreated = NonNullable<
  CustomListSelectionProps['onCustomListSuccessfulCreated']
>;
type OnAddCreatorToCustomList = NonNullable<CustomListSelectionProps['onAddCreatorToCustomList']>;
type OnRemoveCreatorFromCustomList = NonNullable<
  CustomListSelectionProps['onRemoveCreatorFromCustomList']
>;
type OnFavoriteValueChange = NonNullable<CustomListSelectionProps['onFavoriteValueChange']>;
