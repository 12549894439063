import React from 'react';
import { graphql, useFragment } from 'react-relay';

import Field from '../../components/Field/Field';

import styles from './BrandDetails.pcss';
import BrandSelect from './BrandSelect/BrandSelect';

import Image from 'Components/ui/Image/Image';
import Text from 'Atoms/Text/Text';
import { BrandDetails_campaign$key } from 'GraphTypes/BrandDetails_campaign.graphql';

interface Props {
  isAdmin: boolean;
  isOwner: boolean;
  campaign: BrandDetails_campaign$key;
}

const BrandDetails: React.FC<Props> = (props) => {
  const { isAdmin, isOwner, campaign } = props;

  const data = useFragment(
    graphql`
      fragment BrandDetails_campaign on Campaign {
        id
        brand {
          id
          name
          summary
          logo {
            ... on Image {
              transformations {
                collageThumbnailUrl
              }
            }
          }
        }
      }
    `,
    campaign
  );
  const { id: campaignId, brand } = data;

  const brandId = brand?.id;
  const avaSrc = brand?.logo?.transformations?.collageThumbnailUrl;

  return (
    <div className={styles.root}>
      <Image size={88} type="round" src={avaSrc} className={styles.ava} />
      <div className={styles.details}>
        {isAdmin && !isOwner ? (
          <div>
            <Text
              weight="500"
              msg="brief_template.field.brand.title"
              data-test="brandDetails:text:title"
            />
            <Text text={brand?.name} data-test="brandDetails:text:unknown" />
            <Text
              size="sm"
              color="grayDog"
              text={brand?.summary}
              data-test="brandDetails:text:unknown"
            />
          </div>
        ) : (
          <>
            <Field
              title="brief_template.field.brand.title"
              description="brief_template.field.brand.descr"
            >
              <BrandSelect brandId={brandId} name={brand?.name} campaignId={campaignId} />
            </Field>
          </>
        )}
      </div>
    </div>
  );
};

export default BrandDetails;
