import { v4 as uuidv4 } from 'uuid';
import { fetchQuery } from 'react-relay';
import uniq from 'lodash/uniq';

import { UseShopifyQuery, UseShopifyQueryType } from './UseShopify.Query';
import {
  UseOrganizationShopifyQuery,
  UseOrganizationShopifyQueryType,
} from './UseOrganizationShopify.Query';

import { SHOPIFY_OAUTH } from 'Constants/general';
import ErrorHandler from 'Util/errorHandler';
import authenticateWithShopify, {
  AuthenticateWithShopifyMutation,
  AuthenticateWithShopifyMutation$data,
} from 'Mutations/AuthenticateWithShopify.Mutation';
import { promisifyMutation } from 'Util/promisifyMutation';
import _connectCampaignToShopify, {
  ConnectCampaignToShopifyMutation,
} from 'Mutations/ConnectCampaignToShopify.Mutation';
import environment from 'Api/Environment';
import ls from 'Util/localStorage';

enum PostMessageTypeEnum {
  ConnectToShopify = 'ConnectToShopify',
}

type UseShopify = ({ campaignId }?: { campaignId: string }) => HookResult;
const useShopify: UseShopify = (props) => {
  const campaignId = props?.campaignId;
  const hostNamePattern = /^([A-Za-z0-9-]+)\.myshopify\.com$/;
  const LS_KEY = 'state';
  const _makeLsNamespace: MakeLsNamespace = (currentUserId) =>
    `ShopifyOAuthVerify.${currentUserId}`;

  const normalizeUrl: NormalizeUrl = (url) => {
    return !url.startsWith('http') ? `https://${url}` : url;
  };
  const isShopUrlValid: HookResult['isShopUrlValid'] = (url) => {
    const normalizedUrl = normalizeUrl(url);
    try {
      const _url = new URL(normalizedUrl);
      return hostNamePattern.test(_url.hostname);
    } catch (e) {
      return false;
    }
  };

  const defaultScope = 'write_orders,read_products';

  const getShopNameFromUrl: HookResult['getShopNameFromUrl'] = (url) => {
    if (!isShopUrlValid(url)) return null;

    const normalizedUrl = normalizeUrl(url);
    const _url = new URL(normalizedUrl);
    const result = _url.hostname.match(hostNamePattern);
    if (!result || typeof result[1] !== 'string') {
      return null;
    }
    return result[1];
  };

  const authorize: HookResult['authorize'] = (params, options) => {
    const { shopName, campaignId } = params;

    return new Promise<PostMessageData | null>((resolve) => {
      const SHOPIFY_CLIENT_ID = process.env.SHOPIFY_CLIENT_ID;
      console.assert(SHOPIFY_CLIENT_ID, 'SHOPIFY_CLIENT_ID is not set');
      if (!SHOPIFY_CLIENT_ID) {
        resolve(null);
        return null;
      }

      const { origin } = window.location;
      const nonce = uuidv4();
      const searchParams = new URLSearchParams({
        scope: defaultScope,
        redirect_uri: `${origin}${SHOPIFY_OAUTH}`,
        client_id: SHOPIFY_CLIENT_ID,
        state: nonce,
      });
      const url = `https://${shopName}.myshopify.com/admin/oauth/authorize?${searchParams.toString()}`;

      const handler: PostMessageEventHandler = (e) => {
        const data: PostMessageData = e.data;

        if (data.type === PostMessageTypeEnum.ConnectToShopify) {
          const params: PostMessageData['params'] = e?.data?.params;
          const shopName = getShopNameFromUrl(params.shop);

          const handleError = async (errorMessage?: string) => {
            ErrorHandler.error('Shopify authorize error occurred', {
              shopName,
              shop: params.shop,
              timestamp: params.timestamp,
              isNonceValid: nonce === params.state,
              errorMessage,
              isParamExists: {
                state: Boolean(params.state),
                code: Boolean(params.code),
                host: Boolean(params.host),
                hmac: Boolean(params.hmac),
              },
            });
          };

          window.removeEventListener('message', handler, false);
          if (nonce !== params.state || e.data.error || !shopName) {
            handleError();
            resolve({ ...e.data, error: true });
          } else {
            const authenticateWithShopifyMutation =
              promisifyMutation<AuthenticateWithShopifyMutation>(authenticateWithShopify);
            options?.onBeforeAuthenticateWithShopify?.();

            const connectCampaignToShopifyMutation =
              promisifyMutation<ConnectCampaignToShopifyMutation>(connectCampaignToShopify);

            options?.onBeforeAuthenticateWithShopify?.();

            authenticateWithShopifyMutation({
              shopName,
              scope: defaultScope,
              code: params.code,
              hmac: params.hmac,
              state: params.state,
              host: params.host,
              timestamp: params.timestamp,
            })
              .then((response) => {
                const __typename = response?.authenticateWithShopify?.__typename;
                const hasError =
                  (response.authenticateWithShopify &&
                    __typename !== 'AuthenticateWithShopifyPayload') ||
                  !response ||
                  !response.authenticateWithShopify;

                if (hasError) {
                  const errorMessage = getErrorMessageFromResponse(response);
                  handleError(errorMessage);
                  resolve({ ...e.data, response, error: hasError });
                  return;
                }

                if (
                  __typename === 'AuthenticateWithShopifyPayload' &&
                  response.authenticateWithShopify.shopifyAuthorization.id
                ) {
                  const shopifyAuthorizationId =
                    response.authenticateWithShopify.shopifyAuthorization.id;
                  connectCampaignToShopifyMutation({ shopifyAuthorizationId, campaignId })
                    .then(() => {
                      resolve({ ...e.data });
                    })
                    .catch(() => {
                      resolve({ ...e.data, error: true });
                    });
                  return;
                }

                resolve({ ...e.data, response, error: true });
              })
              .catch(() => {
                handleError();
                resolve({ ...e.data, error: true });
              });
          }
        }
      };
      window.addEventListener('message', handler, false);

      const width = 800;
      const height = 600;
      const left = (window.screen.width - width) / 2;
      const top = (window.screen.height - height) / 2.5;
      const winOptions = `width=${width},height=${height},left=${left},top=${top}`;
      window.open(url, 'auth', winOptions);

      return;
    });
  };

  const connectCampaignToShopify: HookResult['connectCampaignToShopify'] = (params) => {
    const { shopifyAuthorizationId, campaignId } = params;
    const connectCampaignToShopifyMutation =
      promisifyMutation<ConnectCampaignToShopifyMutation>(_connectCampaignToShopify);
    return new Promise((resolve, reject) => {
      connectCampaignToShopifyMutation({
        shopifyAuthorizationId,
        campaignId,
      })
        .then((response) => {
          resolve(response);
        })
        .catch(reject);
    });
  };

  const shopifyVerify: HookResult['shopifyVerify'] = (params, options) => {
    return new Promise((resolve, reject) => {
      const authenticateWithShopifyMutation =
        promisifyMutation<AuthenticateWithShopifyMutation>(authenticateWithShopify);

      options?.onBeforeAuthenticateWithShopify?.();

      authenticateWithShopifyMutation({
        scope: params.scope,
        shopName: params.shopName,
        code: params.code,
        hmac: params.hmac,
        state: params.state,
        host: params.host,
        timestamp: params.timestamp,
      })
        .then((response) => {
          const __typename = response?.authenticateWithShopify?.__typename;
          const hasError =
            !response ||
            !response?.authenticateWithShopify ||
            __typename !== 'AuthenticateWithShopifyPayload';

          const resolveParams: ShopifyVerifyResolveData['params'] = {
            code: params.code,
            hmac: params.hmac,
            host: params.host,
            shop: params.shopName,
            state: params.state,
            timestamp: params.timestamp,
          };

          if (hasError) {
            resolve({ params: resolveParams, response, error: hasError });
            return;
          }
          if (
            __typename === 'AuthenticateWithShopifyPayload' &&
            response.authenticateWithShopify.shopifyAuthorization.id
          ) {
            const shopifyAuthorizationId = response.authenticateWithShopify.shopifyAuthorization.id;

            if (!options?.skipConnectCampaignToShopify) {
              connectCampaignToShopify({
                shopifyAuthorizationId,
                campaignId: params.campaignId,
              })
                .then(() => {
                  resolve({ params: resolveParams, error: false });
                })
                .catch(() => {
                  resolve({ params: resolveParams, error: true });
                });
            } else {
              resolve({ params: resolveParams, response, error: false });
            }
          }
        })
        .catch(reject);
    });
  };

  const getErrorMessageFromResponse: HookResult['getErrorMessageFromResponse'] = (response) => {
    const authenticateWithShopify = response?.authenticateWithShopify;
    const __typename = authenticateWithShopify?.__typename;

    let result = '';
    if (
      authenticateWithShopify &&
      (__typename === 'AuthenticateWithShopify_InvalidScopeError' ||
        __typename === 'ValidationError' ||
        __typename === 'NotFoundError')
    ) {
      result = authenticateWithShopify.message;
    }
    return result;
  };

  const isReauthenticationRequired: HookResult['isReauthenticationRequired'] = () => {
    return new Promise<boolean | null>((resolve) => {
      if (campaignId) {
        fetchQuery<UseShopifyQueryType>(environment, UseShopifyQuery, { campaignId }).subscribe({
          next: (response) => {
            const result =
              response?.campaign?.organization?.shopifyAuthorization?.reauthenticationRequired;

            if (result === true || result === false) {
              resolve(result);
              return;
            }

            resolve(null);
          },
        });
      } else {
        fetchQuery<UseOrganizationShopifyQueryType>(
          environment,
          UseOrganizationShopifyQuery,
          {}
        ).subscribe({
          next: (response) => {
            const result =
              response?.currentUser?.organization?.shopifyAuthorization?.reauthenticationRequired;

            if (result === true || result === false) {
              resolve(result);
              return;
            }
            resolve(null);
          },
        });
      }
    });
  };

  const hasEverConnected: HookResult['hasEverConnected'] = () => {
    return new Promise<boolean>((resolve) => {
      if (campaignId) {
        fetchQuery<UseShopifyQueryType>(environment, UseShopifyQuery, { campaignId }).subscribe({
          next: (response) => {
            const hasShopName = Boolean(
              response?.campaign?.organization?.shopifyAuthorization?.shop?.name
            );

            resolve(hasShopName);
          },
        });
      } else {
        fetchQuery<UseOrganizationShopifyQueryType>(
          environment,
          UseOrganizationShopifyQuery,
          {}
        ).subscribe({
          next: (response) => {
            const hasShopName = Boolean(
              response?.currentUser?.organization?.shopifyAuthorization?.shop?.name
            );

            resolve(hasShopName);
          },
        });
      }
    });
  };

  const readCacheCampaigns: HookResult['readCacheCampaigns'] = (params) => {
    const { currentUserId } = params;
    const lsNamespace = _makeLsNamespace(currentUserId);
    let result = [];
    try {
      const lsState = ls.get(LS_KEY, lsNamespace);
      if (Array.isArray(lsState)) {
        result = lsState;
      }
    } catch (e) {
      ErrorHandler.error(`Couldn't read campaign ids from shopify local storage`, e);
    }

    return result;
  };

  const writeCacheCampaigns: HookResult['writeCacheCampaigns'] = (params) => {
    const { currentUserId, campaignIds } = params;
    const lsNamespace = _makeLsNamespace(currentUserId);
    const existingCache = readCacheCampaigns({ currentUserId });
    const result = uniq<string>([...existingCache, ...campaignIds]);
    try {
      ls.set(LS_KEY, result, lsNamespace);
    } catch (e) {
      ErrorHandler.error(`Couldn't save campaign ids to shopify local storage`, e);
    }
  };

  const removeCampaignIdsFromCache: HookResult['removeCampaignIdsFromCache'] = (params) => {
    const { currentUserId, campaignIds } = params;
    const cache = readCacheCampaigns({ currentUserId });
    const result = cache.filter((campaignId) => !campaignIds.includes(campaignId));
    writeCacheCampaigns({ currentUserId, campaignIds: result });
  };

  const clearCacheCampaigns: HookResult['clearCacheCampaigns'] = (params) => {
    const { currentUserId } = params;
    const lsNamespace = _makeLsNamespace(currentUserId);
    ls.remove(LS_KEY, lsNamespace);
  };

  return {
    authorize,
    defaultScope,
    shopifyVerify,
    isShopUrlValid,
    hostNamePattern,
    hasEverConnected,
    getShopNameFromUrl,
    readCacheCampaigns,
    writeCacheCampaigns,
    clearCacheCampaigns,
    connectCampaignToShopify,
    removeCampaignIdsFromCache,
    isReauthenticationRequired,
    getErrorMessageFromResponse,
  };
};

export { useShopify, PostMessageTypeEnum };

// types

interface HookResult {
  /**
   * @desc
   * Returns {true} is the shopify url is valid and {false} if the url is not valid
   */
  isShopUrlValid: (value: string) => boolean;
  /**
   * @desc
   * Returns shop name as a {string} and returns {null} if the url is invalid
   */
  getShopNameFromUrl: (url: string) => string | null;
  /**
   * @desc
   * Valid host name pattern
   */
  hostNamePattern: RegExp;
  /**
   * @desc
   * Authenticate a user and request permissions to install Insense application
   */
  defaultScope: string;
  authorize: (
    params: {
      shopName: string;
      campaignId: string;
    },
    options?: {
      onBeforeAuthenticateWithShopify?: () => void;
    }
  ) => Promise<PostMessageData | null>;

  /**
   * @desc
   * Verifies Shopify authorization for our backend and associates the connection with the organization
   */
  shopifyVerify: (
    params: {
      code: string;
      hmac: string;
      scope: string;
      shopName: string;
      state: string;
      timestamp: string;
      host: string;
      campaignId: string;
    },
    options?: {
      onBeforeAuthenticateWithShopify?: () => void;
      skipConnectCampaignToShopify?: boolean;
    }
  ) => Promise<ShopifyVerifyResolveData | null>;

  connectCampaignToShopify: (params: {
    shopifyAuthorizationId: string;
    campaignId: string;
  }) => Promise<ConnectCampaignToShopifyMutation['response']>;

  /**
   * Try to find error message in response and return if it exists
   */
  getErrorMessageFromResponse: (response: AuthenticateWithShopifyMutation$data) => string;

  /**
   * Checking shopify reauthentication. Returns boolean or null if it is not possible to know reauthentication status
   */
  isReauthenticationRequired: () => Promise<boolean | null>;

  /**
   * Checks if a connection has ever been established.
   */
  hasEverConnected: () => Promise<boolean>;

  readCacheCampaigns: (params: { currentUserId: string }) => string[];

  writeCacheCampaigns: (params: { currentUserId: string; campaignIds: string[] }) => void;

  clearCacheCampaigns: (params: { currentUserId: string }) => void;

  removeCampaignIdsFromCache: (params: { currentUserId: string; campaignIds: string[] }) => void;
}

type NormalizeUrl = (url: string) => string;
type PostMessageEventHandler = Parameters<typeof window.addEventListener<'message'>>[1];
type PostMessageData = {
  error: boolean;
  type: PostMessageTypeEnum;
  params: {
    code: string;
    hmac: string;
    host: string;
    shop: string;
    state: string;
    timestamp: string;
  };
  response?: AuthenticateWithShopifyMutation$data;
};

type ShopifyVerifyResolveData = {
  error: boolean;
  params: {
    code: string;
    hmac: string;
    host: string;
    shop: string;
    state: string;
    timestamp: string;
  };
  response?: AuthenticateWithShopifyMutation$data;
};

type MakeLsNamespace = (currentUserId: string) => string;

export type { PostMessageData };
