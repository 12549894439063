import React, { useState, useEffect, useMemo, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { graphql, useQueryLoader } from 'react-relay';
import track from 'react-tracking';
import remove from 'lodash/remove';
import forEach from 'lodash/forEach';
import { isTablet, BrowserView } from 'react-device-detect';
import classnames from 'classnames';

import { NewCampaignButton } from '../NewCampaignButton';

import styles from './Campaigns.pcss';
import CampaignStageFilter, { CampaignStages } from './CampaignStageFilter/CampaignStageFilter';
import NewCampaignsList from './CampaignsList/CampaignsList';
import CampaignStub from './CampaignsList/CampaignStub/CampaignStub';

import BrandsFilter from 'Organisms/BrandsFilter/BrandsFilter';
import ls from 'Util/localStorage';
import { CampaignsQuery as QueryType, CampaignStage } from 'GraphTypes/CampaignsQuery.graphql';
import { amplitude } from 'Helpers/amplitude';
import Text from 'Components/ui/Text/Text';
import Input from 'Components/ui/Input/Input';
import Toggle from 'Components/ui/Toggle/Toggle';

export const CampaignsQuery = graphql`
  query CampaignsQuery(
    $brandNames: [String!]
    $stages: [CampaignStage!]
    $textQuery: String
    $onlySubscribed: Boolean
  ) {
    ...CampaignsList_campaigns
      @arguments(
        brandNames: $brandNames
        stages: $stages
        textQuery: $textQuery
        onlySubscribed: $onlySubscribed
      )
    currentUser {
      admin
      organization {
        id
        subscription {
          planId
          canBrowseCreators
        }
        counters(onlySubscribed: $onlySubscribed) {
          newApplicants
          projectsWithSubmittedContent
          unreadMessagesInCompletedCampaigns: projectsWithUnreadEventsExcludingOfferAcceptances(
            campaignStages: [COMPLETED]
          )
          unreadMessagesInUncompletedCampaigns: projectsWithUnreadEventsExcludingOfferAcceptances(
            campaignStages: [ACTIVE, IN_REVIEW]
          )
        }
      }
    }
  }
`;

interface Props {
  className?: string;
  organizationId?: string;
  canCreateLongTermCampaigns?: boolean;
  computedStage: CampaignStage;
}

const Campaigns: React.FC<Props> = (props) => {
  const { className, computedStage, canCreateLongTermCampaigns } = props;

  const archivedList = JSON.parse(ls.get('filtered_brands'));

  const location = useLocation();
  const defaultStage = location.state?.campaignStage || computedStage;
  const defaultOnlySubscribed = location.state?.onlySubscribed || undefined;

  const [brands, setBrands] = useState(archivedList || []);
  const [stage, setStage] = useState<CampaignStage>(defaultStage);
  const [textQuery, setTextQuery] = useState('');
  const [onlySubscribed, setSubscribeOnly] = useState<boolean | undefined>(defaultOnlySubscribed);

  const [queryReference, loadQuery] = useQueryLoader<QueryType>(CampaignsQuery);

  const stagesForRequest: CampaignStage[] = stage === 'ACTIVE' ? ['ACTIVE', 'IN_REVIEW'] : [stage];

  const brandNames = useMemo(() => {
    const names: string[] = [];
    forEach(brands, (item) => {
      const id = Object.keys(item)[0];
      names.push(item[id]);
    });

    return names;
  }, [brands]);

  useEffect(() => {
    loadQuery(
      { brandNames, stages: stagesForRequest, textQuery, onlySubscribed },
      { fetchPolicy: 'network-only' }
    );
  }, [brandNames, stage, textQuery, onlySubscribed]);

  const handleAddBrandToList = (id?: string, name?: string, check?: boolean) => {
    if (!id) {
      setBrands([]);
      ls.set('filtered_brands', JSON.stringify([]));
      return;
    }

    const newList: { [key: string]: string | undefined }[] = [];
    if (check) {
      newList.push({ [id]: name });
    } else {
      remove(newList, (item) => {
        return item[id];
      });
    }
    setBrands(newList);
    ls.set('filtered_brands', JSON.stringify(newList));
  };

  const handleQueryChange = (newTextQuery: string) => {
    setTextQuery(newTextQuery);
  };

  const handleSubscribeChange = () => {
    setSubscribeOnly(onlySubscribed ? undefined : true);
  };

  const handleStageChange = (stage: CampaignStage) => {
    setStage(stage);
    const eventData = {
      name: '',
      id: 0,
      category: 'dashboard',
    };
    switch (stage) {
      case 'ACTIVE': {
        eventData.name = 'active_campaigns_tab_click';
        eventData.id = 114;
        break;
      }
      case 'DRAFT': {
        eventData.name = 'drafts_tab_click';
        eventData.id = 115;
        break;
      }
      case 'COMPLETED': {
        eventData.name = 'closed_tab_click';
        eventData.id = 116;
        break;
      }
      default: {
        break;
      }
    }
    if (!eventData.name) return;
    amplitude.sendEvent(eventData);
  };

  const preloader = <CampaignStub stage={stage} withBars />;

  return (
    <div className={classnames(className, styles.container)}>
      {!isTablet && (
        <BrowserView>
          <header className={styles.header}>
            <div className={styles.titleWrap}>
              <Text
                type="d2"
                msg="dashboard.my_campaign"
                className={styles.title}
                data-test="campaigns:text:myCampaign"
              />
              <div className={styles.titleActions}>
                <Toggle
                  on={!!onlySubscribed}
                  onChange={handleSubscribeChange}
                  msg="campaigns.filter_campaigns.subscribed_only"
                />
                <NewCampaignButton canCreateLongTermCampaigns={canCreateLongTermCampaigns} />
              </div>
            </div>
            <div className={styles.params}>
              <Suspense
                fallback={<CampaignStages currentStage={stage} onStageChange={handleStageChange} />}
              >
                <CampaignStageFilter currentStage={stage} onStageChange={handleStageChange} />
              </Suspense>
              <div className={styles.serachInputWrap}>
                <Input
                  placeholderMsg="campaigns.serach_input.placeholder"
                  handleChange={handleQueryChange}
                  className={styles.serachInput}
                  data-test="campaigns:input:serachInput"
                />
              </div>
              <BrandsFilter
                selectedItems={brands}
                onAdd={handleAddBrandToList}
                className={styles.brands}
              />
            </div>
          </header>
        </BrowserView>
      )}
      <div className={styles.root}>
        <Suspense fallback={preloader}>
          {queryReference && (
            <NewCampaignsList
              brandNames={brandNames}
              stages={stagesForRequest}
              textQuery={textQuery}
              onlySubscribed={onlySubscribed}
              queryReference={queryReference}
            />
          )}
        </Suspense>
      </div>
    </div>
  );
};

export default track({
  section: 'campaigns',
})(Campaigns);
