import compact from 'lodash/compact';

import { PostsStatistic_creator$data } from 'GraphTypes/PostsStatistic_creator.graphql';
import { createShortNumber, createPercent } from 'Util/numberFormatter';

export const createData = (data: PostsStatistic_creator$data) => {
  const userInsightsData = data.userInsightsData;

  const medias = userInsightsData?.media;
  const mediaAverageReach = userInsightsData?.mediaAverageReach;
  const mediaAverageImpressions = userInsightsData?.mediaAverageImpressions;
  const mediaEngagementRate = userInsightsData?.mediaEngagementRate;

  return compact([
    {
      title: 'creator_statistic.subtitles.posts',
      tooltipMsg: 'creator_statistic.subtitles.posts.tooltip',
      value: createShortNumber(medias),
    },
    {
      title: 'creator_statistic.subtitles.posts.reach',
      tooltipMsg: 'creator_statistic.subtitles.posts.reach.tooltip',
      value: createShortNumber(mediaAverageReach),
    },
    {
      title: 'creator_statistic.subtitles.posts.impressions',
      tooltipMsg: 'creator_statistic.subtitles.posts.impressions.tooltip',
      value: createShortNumber(mediaAverageImpressions),
    },
    {
      title: 'creator_statistic.subtitles.posts.er',
      tooltipMsg: 'creator_statistic.subtitles.posts.er.tooltip',
      value: createPercent(mediaEngagementRate),
    },
  ]);
};
