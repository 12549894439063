import React, { Suspense, useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLazyLoadQuery, graphql } from 'react-relay';
import track from 'react-tracking';
import { useParams, useNavigate } from 'react-router-dom';
import compact from 'lodash/compact';

import styles from './AdminAdvertiser.pcss';
import Info from './Info/Info';
import AdvertiserCampaigns from './AdvertiserCampaigns/AdvertiserCampaigns';

import updateOrganization from 'Mutations/UpdateOrganization.Mutation';
import { getProjectsLink, getOrganizationPerformanceLink } from 'Util/links';
import { createPlanMsg } from 'Util/textFormatter';
import { dateStringToNewYorkDate } from 'Util/dateCreator.js';
import Page from 'Templates/Page/Page';
import Stats from 'Atoms/Stats/Stats';
import IconMenu from 'Molecules/IconMenu/IconMenu';
import Toggle from 'Components/ui/Toggle/Toggle';
import icons from 'Icons';
import { AdminAdvertiserQuery as QueryType } from 'GraphTypes/AdminAdvertiserQuery.graphql';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import ManualTransactions from 'Modal/admin/ManualTransactions/ManualTransactions';
import CancelSubscription from 'Modal/admin/CancelSubscription/CancelSubscription';
import { ERROR_ROUTE } from 'Constants/general';
import { createSum } from 'Util/numberFormatter';

const AdminAdvertiserQuery = graphql`
  query AdminAdvertiserQuery($id: ID!) {
    organization(id: $id) {
      id
      ...Info_organization
      test
      paymentAccount {
        balance
        currency
      }
      subscription {
        id
        endsAt
        planId
        transactionFee
      }
    }
    currentUser {
      organization {
        id
      }
      permissions
    }
  }
`;

const AdminAdvertiser: React.FC = () => {
  const { id: organizationId = '' } = useParams<{ id: string }>();

  const intl = useIntl();

  const navigate = useNavigate();

  const { openDrawer } = useContext(DrawerContext);

  const data = useLazyLoadQuery<QueryType>(AdminAdvertiserQuery, {
    id: organizationId,
  });

  const permissions = data.currentUser?.permissions || [];
  const organization = data.organization;
  const organizationDemo = !!organization?.test;
  const subscription = organization?.subscription;
  const subscriptionId = subscription?.id;
  const currency = organization?.paymentAccount?.currency;
  const userOrganizationId = data.currentUser?.organization?.id;

  useEffect(() => {
    if (!permissions.includes('ADMIN__MANAGE_ALL_ORGANIZATIONS')) {
      navigate(ERROR_ROUTE);
    }
  }, [permissions]);

  const handleTopupClick = () => {
    openDrawer('manual-transactions');
  };

  const handleCancelPlanClick = () => {
    openDrawer('cancel-organization-subscription');
  };

  const handleDemoParamChange = () => {
    updateOrganization({ id: organizationId, test: !organizationDemo });
  };

  const handleChatsClick = () => {
    const link = getProjectsLink({ organizationId });
    window.open(link);
  };

  const handlePerformanceClick = () => {
    const link = getOrganizationPerformanceLink({ organizationId });
    window.open(link);
  };

  const statItems = [
    {
      subtitleMsg: 'summary.balance',
      id: 'balance',
      value: createSum(organization?.paymentAccount?.balance, 'USD'),
    },
    {
      subtitleMsg: 'summary.plan',
      id: 'planId',
      value: subscription?.planId
        ? intl.formatMessage({ id: createPlanMsg(subscription.planId) })
        : '—',
    },
    {
      subtitleMsg: 'summary.end_subs',
      id: 'endsAt',
      value: dateStringToNewYorkDate(subscription?.endsAt),
    },
    {
      subtitleMsg: 'summary.transactioFee',
      id: 'transactioFee',
      value: subscription?.transactionFee
        ? `${(subscription.transactionFee * 100).toFixed(1)}%`
        : '—',
    },
  ];

  const dropDownItems = compact([
    {
      id: 'topup_balance',
      textData: {
        textData: {
          msg: 'advertiser.dropdown_topup_balance',
        },
      },
      onClick: handleTopupClick,
    },
    subscriptionId
      ? {
          id: 'cancel_subscription',
          textData: {
            textData: {
              msg: 'advertiser.dropdown_cancel_subscription',
            },
          },
          onClick: handleCancelPlanClick,
        }
      : null,

    {
      id: 'go_to_chats',
      textData: {
        textData: {
          msg: 'advertiser.dropdown.chats',
        },
      },
      onClick: handleChatsClick,
    },
    {
      id: 'go_to_chats',
      textData: {
        textData: {
          msg: 'advertiser.dropdown.performance',
        },
      },
      onClick: handlePerformanceClick,
    },
  ]);
  const el = (
    <div>
      <header className={styles.header}>
        <Stats items={statItems} />
        <IconMenu
          textBtnData={{ icon: icons.more }}
          className={styles.dropDown}
          dropDownData={{ list: dropDownItems }}
        />
        <Tooltip id="test_param" place="bottom" tooltipMsg="advertiser.transactions.show.tooltip">
          <Toggle
            msg="advertiser.transactions.show"
            on={organizationDemo}
            labelSide="right"
            className={styles.transactionShowToggle}
            onChange={handleDemoParamChange}
          />
        </Tooltip>
      </header>
    </div>
  );

  return (
    <Page className={styles.root}>
      <div>{organization && <Info organization={organization} el={el} />}</div>
      {userOrganizationId && (
        <Suspense fallback={null}>
          <div className={styles.container}>
            <AdvertiserCampaigns id={organizationId} userOrganizationId={userOrganizationId} />
          </div>
        </Suspense>
      )}
      <ManualTransactions organizationId={organizationId} />
      {subscriptionId && <CancelSubscription subscriptionId={subscriptionId} />}
    </Page>
  );
};

export default track(
  {
    page: 'admin_advertiser',
  },
  { dispatchOnMount: true }
)(AdminAdvertiser);
