import React, { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { Element } from 'react-scroll';

import { BriefContext } from '../../../Brief.Context';
import Field from '../components/Field/Field';
import Section from '../components/Section/Section';
import With from '../components/With/With';
import SegmentedControls from '../components/SegmentedControls/SegmentedControls';

import { getPostingTypesList, checkValidation } from './util';
import styles from './Deliverables.pcss';

import Text from 'Components/ui/Text/Text';
import { Deliverables_campaign$key } from 'GraphTypes/Deliverables_campaign.graphql';
import updateBrief from 'Api/mutations/UpdateBrief.Mutation';
import { amplitude } from 'Helpers/amplitude';

interface Props {
  campaign: Deliverables_campaign$key;
}

const Deliverables: React.FC<Props> = (props) => {
  const { campaign } = props;
  const intl = useIntl();
  const descriptionPreset = intl.formatMessage({
    id: 'brief_template.field.deliverables.description.descr.preset',
  });

  const data = useFragment(
    graphql`
      fragment Deliverables_campaign on Campaign {
        id
        brief {
          id
          ... on V2Brief {
            publishingRequired
            taskDescription
          }
        }
      }
    `,
    campaign
  );

  const taskDescription = data.brief?.taskDescription || '';
  const briefId = data.brief?.id || '';
  const publishingRequired = data.brief?.publishingRequired;
  const campaignId = data.id;

  const [briefState, briefDispatch] = useContext(BriefContext);

  const { showErrors, shownElementsWithPossibleError } = briefState;

  useEffect(() => {
    const errors = checkValidation(data, descriptionPreset);
    briefDispatch({ key: 'deliverablesErrors', value: errors });
    briefDispatch({ key: 'deliverablesInfoFilled', value: errors.length === 0 });
  }, [data]);

  const handleBriefChange = (data: { taskDescription: string }) => {
    updateBrief({ id: briefId, ...data });
  };

  if (!briefId || !briefState.creatorsInfoFilled || !briefState.productInfoFilled) return null;

  const handlePostingChange = (data: { publishingRequired?: boolean | null }) => {
    amplitude.sendEvent<497>({
      id: '497',
      category: 'brief',
      name: 'deliverables_posting_change',
      param: {
        campaign_id: campaignId,
        posting: data.publishingRequired,
      },
    });
    briefDispatch({ key: 'briefIsSaving', value: true });
    updateBrief({ id: briefId, publishingRequired: data.publishingRequired });
  };

  const postingTypesList = getPostingTypesList({ campaignId });

  return (
    <Section
      titleMsg="brief_template.section.deliverables"
      className={styles.section}
      titleClassName={styles.title}
    >
      <With condition={true} name="deliverablesInfoFilled">
        <div>
          <div className={styles.root}>
            <Text
              type="md"
              msg="brief_template.section.creative_assets.deliverables.title"
              className={styles.title}
              data-test="deliverables:text:title"
            />
            <Text
              type="label"
              msg="brief_template.section.creative_assets.deliverables.descr"
              className={styles.descr}
              data-test="deliverables:text:descr"
            />
            <With condition={true} name="taskDescription">
              <Element name="taskDescription">
                <Field
                  title=""
                  element="input"
                  name="taskDescription"
                  className={styles.field}
                  elementData={{
                    multiple: true,
                    maxlength: 500,
                    defaultValue: taskDescription || descriptionPreset,
                    onChange: handleBriefChange,
                    textAreaData: { minRows: 1, maxRows: 5 },
                    dataTest: 'input:deliverablesInstruction',
                    error:
                      shownElementsWithPossibleError?.includes('taskDescription') &&
                      showErrors &&
                      (!taskDescription || taskDescription === descriptionPreset),
                  }}
                />
              </Element>
            </With>
            <With condition={true} name="publishingRequired">
              <Element name="publishingRequired">
                <Field
                  title="brief_template.product_seeding.posting.title"
                  description="brief_template.product_seeding.posting.descr"
                >
                  <SegmentedControls
                    id="publishingRequired"
                    currentValue={publishingRequired}
                    items={postingTypesList}
                    onChange={handlePostingChange}
                    error={
                      shownElementsWithPossibleError?.includes('publishingRequired') &&
                      showErrors &&
                      typeof publishingRequired !== 'boolean'
                    }
                  />
                </Field>
              </Element>
            </With>
          </div>
        </div>
      </With>
    </Section>
  );
};

export default Deliverables;
