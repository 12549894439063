import React, { useCallback } from 'react';
import { useFragment, graphql } from 'react-relay';
import { KeyType } from 'react-relay/relay-hooks/helpers';
import xor from 'lodash/xor';
import compact from 'lodash/compact';

import styles from './ScreeningQuestionsFilter.pcss';

import { amplitude } from 'Helpers/amplitude';
import ProgressiveInput from 'Components/ProgressiveInput/ProgressiveInput';
import { CheckboxItemType } from 'Components/ui/CheckboxSelect/CheckboxSelect';
import {
  ScreeningQuestionsFilter_campaign$key,
  ScreeningQuestionsFilter_campaign$data,
} from 'GraphTypes/ScreeningQuestionsFilter_campaign.graphql';
import { Props as TextProps } from 'Components/ui/Text/Text';

interface Props {
  screeningQuestionIds?: string[];
  onQuestionsChange: (value?: string[]) => void;
  campaign: ScreeningQuestionsFilter_campaign$key;
}

const ScreeningQuestionsFilter: React.FC<Props> = (props) => {
  const { campaign, screeningQuestionIds = [], onQuestionsChange } = props;

  const data = useFragment<KeyType<ScreeningQuestionsFilter_campaign$data>>(
    graphql`
      fragment ScreeningQuestionsFilter_campaign on Campaign {
        brief {
          ... on V2Brief {
            screeningQuestions {
              edges {
                node {
                  id
                  text
                  type
                  preferredValue
                }
              }
            }
          }
        }
      }
    `,
    campaign
  );

  const items = compact(
    data.brief?.screeningQuestions?.edges?.map((item) => {
      if (!item?.node) return null;

      const { id, text, preferredValue, type } = item.node;

      if (type !== 'BINARY') return null;

      const subtitleAnswer = preferredValue ? 'YES' : 'NO';

      const subtitleData: TextProps = {
        msg: 'creators.filter.screening_question.preferred',
        formatValues: {
          answer: <b>{subtitleAnswer}</b>,
        },
      };

      return { id, label: text, isChecked: screeningQuestionIds.includes(id), subtitleData };
    })
  );

  const handleQuestionSelect = useCallback(
    (newValue?: string | number) => {
      if (!newValue) {
        onQuestionsChange(undefined);
        return;
      }

      amplitude.sendEvent<374>({
        id: '374',
        category: 'campaign',
        name: 'screening_question_select',
      });

      const questions = xor(screeningQuestionIds, [newValue.toString()]);
      onQuestionsChange(questions);
    },
    [screeningQuestionIds]
  );

  const handleClearQuestions = () => {
    onQuestionsChange(undefined);
  };

  const handleOpenStateChange = () => {
    amplitude.sendEvent<373>({
      id: '373',
      category: 'campaign',
      name: 'screening_questions_filter_click',
    });
  };

  if (items?.length === 0) return null;

  const hasSelectedQuestions = screeningQuestionIds.length > 0;

  return (
    <ProgressiveInput
      type="checkboxSelect"
      hideTitle={true}
      inputProps={{
        items: items as CheckboxItemType[],
        placeholderMsg: 'creators.filter.screening_question',
        handleClick: handleQuestionSelect,
        className: styles.filter,
        containerClassName: styles.container,
        groupSubtitle: 'creators.filter.screening_question.subtitle',
        place: 'bottomRight',
        hideSelected: true,
        handleOpenStateChange: handleOpenStateChange,
        handleClear: handleClearQuestions,
      }}
      isDirty={hasSelectedQuestions}
    />
  );
};

export default ScreeningQuestionsFilter;
