import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SubscriptionParamsType } from 'Helpers/amplitude.types';
import { amplitude } from 'Helpers/amplitude';
import { useSubscribeToCampaignQueryMutation } from 'Mutations/SubscribeToCampaign.Mutation';
import { useUnsubscribeFromCampaignQueryMutation } from 'Mutations/UnsubscribeFromCampaign.Mutation';

interface Props {
  userSubscribed: boolean;
  campaignId: string;
  children?: React.ReactNode;
  eventFn?: (data: SubscriptionParamsType) => void;
  currentUserId?: string;
}

const CampaignSubscription: React.FC<Props> = (props) => {
  const { children, campaignId, currentUserId, eventFn, userSubscribed } = props;

  const [subscribeToCampaign] = useSubscribeToCampaignQueryMutation();

  const [searchParams] = useSearchParams();
  const subscribeUserId = searchParams.get('subscribeUserId');
  useEffect(() => {
    if (subscribeUserId && subscribeUserId === currentUserId) {
      subscribeToCampaign({
        variables: {
          input: {
            campaignId,
          },
        },
      });
    }
  }, [subscribeUserId]);

  const [unsubscribeFromCampaign] = useUnsubscribeFromCampaignQueryMutation({
    variables: {
      input: {
        campaignId,
      },
    },
  });

  const handleSubscribe = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();

    amplitude.sendEvent<95>({
      id: '95',
      category: 'campaign',
      name: 'email_notification_toggle_click',
      param: { type: 'subscribe' },
    });

    eventFn?.({ campaignId, type: 'enabled' });

    subscribeToCampaign({
      variables: {
        input: {
          campaignId,
        },
      },
    });
  };

  const handleUnsubscribe = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();

    amplitude.sendEvent<95>({
      id: '95',
      category: 'campaign',
      name: 'email_notification_toggle_click',
      param: { type: 'unsubscribe' },
    });

    eventFn?.({ campaignId, type: 'disabled' });

    unsubscribeFromCampaign({
      variables: {
        input: {
          campaignId,
        },
      },
    });
  };

  return <div onClick={userSubscribed ? handleUnsubscribe : handleSubscribe}>{children}</div>;
};

export default CampaignSubscription;
