import { graphql } from 'react-relay';

import type { BlocklistQuery as QueryType } from 'GraphTypes/BlocklistQuery.graphql';
import type { BlocklistItems_organization$key } from 'GraphTypes/BlocklistItems_organization.graphql';

const BlocklistQuery = graphql`
  query BlocklistQuery {
    currentUser {
      admin
      permissions
      organization {
        id
        newClient
        churned
        subscription {
          paused
        }
        ...BlocklistItems_organization
      }
    }
  }
`;

export { BlocklistQuery };

// types

type BlocklistQueryType = QueryType & {
  readonly response: QueryType['response'] & {
    readonly currentUser:
      | (QueryType['response']['currentUser'] & {
          readonly organization: BlocklistItems_organization$key | null;
        })
      | null;
  };
};

export type { BlocklistQueryType };
