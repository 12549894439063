import React, { useEffect, useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import track from 'react-tracking';
import remove from 'lodash/remove';

import Post from '../Post/Post';

import styles from './Medias.css';

import { Medias_creator$key, Medias_creator$data } from 'GraphTypes/Medias_creator.graphql';

interface Props {
  creator?: Medias_creator$key;
  onHideTab: (tab: string) => void;
}

const MediasList: React.FC<Props> = (props) => {
  const { creator, onHideTab } = props;

  const data = useFragment(
    graphql`
      fragment Medias_creator on Creator
      @argumentDefinitions(first: { type: "Int", defaultValue: 30 }) {
        ... on InstagramCreator {
          user {
            followedByCount
          }
          medias(first: $first) {
            edges {
              node {
                id
                commentCount
                likeCount
                videoUrl
                viewCount
                caption
                thumbnailUrl
                instagramUrl
                standardResUrl
              }
            }
          }
          posts: insightsMedias(placements: [FEED, REEL], first: 30) {
            edges {
              node {
                id
                engagement
                mediaUrl
                thumbnailUrl
                type
                caption
                impressions
                reach
                instagramUrl: permalink
              }
            }
          }
        }
      }
    `,
    creator
  );

  if (!data) return null;

  const medias = data.medias?.edges;
  const posts = data.posts?.edges;
  const followedByCount = data.user?.followedByCount;

  if ((!medias || medias.length === 0) && (!posts || posts.length === 0)) return null;

  const postsList = posts && posts?.length > 0 ? posts : medias;

  const [list, setList] = useState<
    | NonNullable<Medias_creator$data['medias']>['edges']
    | NonNullable<Medias_creator$data['posts']>['edges']
    | null
    | undefined
  >(postsList);

  useEffect(() => {
    if (!list || length === 0) {
      onHideTab('last_posts');
    }
  }, [list]);

  const handleImageError = (id: string) => {
    const newList = [...list];
    remove(newList, (item) => {
      return item?.node?.id === id;
    });
    setList(newList);
  };

  return (
    <div className={styles.list}>
      {list?.map((item, index) => {
        const id = item?.node?.id || index.toString();
        const videoUrl = item?.node?.videoUrl;

        const engagement =
          item?.node?.engagement && followedByCount
            ? item?.node?.engagement / followedByCount
            : undefined;

        return (
          <Post
            key={id}
            id={id}
            {...item?.node}
            engagement={engagement}
            finalVideoUrl={videoUrl}
            onLoadError={handleImageError}
          />
        );
      })}
    </div>
  );
};

const Medias = track({
  section: 'medias',
})(MediasList);

export default Medias;
