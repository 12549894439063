import React, { useContext, useState } from 'react';
import classnames from 'classnames';
import { useIntl } from 'react-intl';

import styles from './ArchiveProjectRequest.pcss';
import { reasons } from './util';

import { amplitude } from 'Helpers/amplitude';
import requestProjectArchivation from 'Mutations/RequestProjectArchivation.Mutation';
import Text from 'Components/ui/Text/Text';
import Textarea from 'Components/ui/Textarea/Textarea';
import Drawer from 'Components/ui/Drawer/Drawer';
import Button from 'Components/ui/Button/Button';
import SimpleLink from 'Components/SimpleLink/SimpleLink';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import RadioButton from 'Components/ui/RadioButton/RadioButton';
import { HELLO_EMAIL } from 'Constants/general';

const OTHER = 'Other';

interface Props {
  projectId: string;
  organizationId?: string;
  creatorId?: string;
}

const ArchiveProjectRequest: React.FC<Props> = (props) => {
  const { projectId, organizationId = '', creatorId = '' } = props;
  const { closeDrawer } = useContext(DrawerContext);

  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState('');
  const [comment, setComment] = useState('');
  const [requestSent, setRequestSent] = useState(false);

  const handleRequestDone = () => {
    setLoading(false);
    setRequestSent(true);
  };

  const handleClose = () => {
    closeDrawer(`archive-project-by-contractor-${projectId}`);
  };

  const handleSendRequest = () => {
    amplitude.sendEvent<358>({
      id: '358',
      category: 'project',
      name: 'decline_request_by_contractor',
      param: {
        project_id: projectId,
        organization_id: organizationId,
        creator_id: creatorId,
        reason,
        reason_other: comment || undefined,
      },
    });
    setLoading(true);
    requestProjectArchivation(
      { projectId, reason, comment, side: 'CONTRACTOR' },
      handleRequestDone
    );
  };

  if (requestSent) {
    return (
      <Drawer rootKey={`archive-project-by-contractor-${projectId}`} className={styles.drawer}>
        <div className={styles.drawerContainer}>
          <div className={styles.drawerContent}>
            <Text type="d2" msg="archive_project_request_modal.request_sent.title" />
            <Text
              type="sm"
              msg="archive_project_request_modal.request_sent.descr"
              formatValues={{
                email: (
                  <SimpleLink href={`mailto:${HELLO_EMAIL}`} target="_blank">
                    <Text
                      type="sm"
                      msg="archive_project_request_modal.request_sent.email"
                      className={styles.email}
                    />
                  </SimpleLink>
                ),
              }}
              className={styles.descr}
            />
          </div>
          <Button
            color="black"
            msg="archive_project_request_modal.request_sent.btn"
            loading={loading}
            disabled={(!reason || loading) && !requestSent}
            onClick={handleClose}
          />
        </div>
      </Drawer>
    );
  }

  const handleCommentChange = (newComment: string) => {
    setComment(newComment.trim());
  };

  const finalValue = reason === OTHER ? comment : reason;

  return (
    <Drawer rootKey={`archive-project-by-contractor-${projectId}`} className={styles.drawer}>
      <div className={styles.drawerContainer}>
        <Text type="d2" msg="archive_project_request_by_contactor_modal.title" />
        <Text
          type="sm"
          msg="archive_project_request_by_contactor_modal.descr"
          className={styles.descr}
        />
        <div className={styles.drawerContent}>
          <ul className={styles.reasons}>
            {reasons.map((item) => {
              const value = intl.formatMessage({ id: item.msg });
              const handleClick = () => {
                setComment('');
                setReason(value);
              };
              const isActive = reason === value;
              if (isActive && reason === OTHER) return null;
              return (
                <li
                  key={value}
                  className={classnames(styles.reason, { [styles.active]: isActive })}
                  onClick={handleClick}
                >
                  <RadioButton checked={isActive} />
                  <Text type="md" msg={item.msg} />
                </li>
              );
            })}
          </ul>
          {reason === OTHER && (
            <Textarea
              placeholderMsg="archive_project_request_modal.reason.other.placeholder"
              labelMsg="archive_project_request_modal.reason.other.placeholder"
              value={comment || undefined}
              bordered={true}
              handleChange={handleCommentChange}
              className={styles.input}
            />
          )}
        </div>
        <div className={styles.btnContainer}>
          <Button
            fluid
            color="black"
            msg="archive_project_request_modal.btn"
            loading={loading}
            disabled={(!finalValue || loading) && !requestSent}
            onClick={handleSendRequest}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default ArchiveProjectRequest;
