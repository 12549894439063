import React, { useMemo, useContext } from 'react';
import { graphql, useFragment } from 'react-relay';
import classnames from 'classnames';

import CreatorDetails from '../../../../Creators/components/CreatorDetails/CreatorDetails';

import styles from './Shipment.pcss';

import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { Checkbox } from 'Components/ui/Checkbox';
import { Shipment_project$key } from 'GraphTypes/Shipment_project.graphql';
import { amplitude } from 'Helpers/amplitude';
import { ChatDrawerContext } from 'AdvertiserPage/Campaign/ChatDrawer/ChatDrawer.Context';

type OrderStatusType = 'created' | 'notCreated' | 'error' | 'processing';

interface Props {
  project: Shipment_project$key;
  campaignId?: string;
  disabledShipment?: boolean;
  selected?: boolean;
  onSelectProject: (projectId: string, forceSelected?: boolean) => void;
  onCreateOrder: (projectId: string) => void;
  isAdmin: boolean;
}

const Shipment: React.FC<Props> = (props) => {
  const { project, disabledShipment, selected, campaignId, onSelectProject } = props;

  const { openProjectChat } = useContext(ChatDrawerContext);

  const unpackedProject = useFragment(
    graphql`
      fragment Shipment_project on Project {
        id
        shopifyOrder {
          id
          state
        }
        shippingAddress {
          address1
          address2
          city
          country {
            name
          }
          province {
            name
          }
          phone
          firstName
          lastName
          zip
        }
        creator {
          ownership {
            owner {
              email
            }
          }
          id
          username
          ...CreatorDetails_creator
        }
      }
    `,
    project
  );

  if (!unpackedProject) return null;
  const { shippingAddress } = unpackedProject;
  const fullAddress = `${shippingAddress?.address1}${
    shippingAddress?.address2 ? ' ' + shippingAddress?.address2 : ''
  }, ${shippingAddress?.city}, ${
    shippingAddress?.province?.name ? shippingAddress?.province?.name + ', ' : ''
  }${shippingAddress?.zip}, ${shippingAddress?.country?.name}`;

  const shipmentReceiverName = `${unpackedProject?.shippingAddress?.firstName} ${unpackedProject?.shippingAddress?.lastName}`;

  const orderStatus = useMemo<OrderStatusType>(() => {
    if (!unpackedProject?.shopifyOrder) {
      return 'notCreated';
    }
    if (unpackedProject?.shopifyOrder?.state === 'PENDING') {
      return 'processing';
    }
    if (unpackedProject?.shopifyOrder?.state === 'SUCCESS') {
      return 'created';
    }
    return 'error';
  }, [unpackedProject?.shopifyOrder]);

  const handleChatClick = () => {
    amplitude.sendEvent<325>({
      id: '325',
      category: 'shipment',
      name: 'chat_click',
      param: { campaign_id: `${campaignId}` },
    });
    openProjectChat(unpackedProject.id);
  };

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div
          className={classnames(styles.checkboxContainer, {
            [styles.pointer]:
              (orderStatus === 'notCreated' || orderStatus === 'error') && !disabledShipment,
          })}
          onClick={
            (orderStatus === 'notCreated' || orderStatus === 'error') && !disabledShipment
              ? () => onSelectProject(unpackedProject?.id)
              : undefined
          }
        >
          {orderStatus !== 'created' && orderStatus !== 'processing' && (
            <Checkbox
              disabled={disabledShipment}
              checked={selected}
              data-test="shipment:checkbox:unknown"
            />
          )}
        </div>
        {!!unpackedProject.creator && (
          <div className={styles.creator}>
            <CreatorDetails
              creator={unpackedProject.creator}
              shipmentReceiverName={shipmentReceiverName}
              shipment
              disableBadge
            />
          </div>
        )}
        <div className={styles.orderStatus}>
          <Text
            type="label"
            color={'grey'}
            msg={'shipment.order_status'}
            data-test="shipment:text:unknown"
          />
          <Text
            msg={`shipment.order.status.${orderStatus}`}
            type="md"
            className={classnames(styles.default, {
              [styles.error]: orderStatus === 'error',
              [styles.notCreated]: orderStatus === 'notCreated',
              [styles.created]: orderStatus === 'created',
            })}
            data-test="shipment:text:unknown"
          />
        </div>
        <div className={styles.email}>
          <Text
            type="label"
            color={'grey'}
            msg={'general.email'}
            data-test="shipment:text:unknown"
          />
          <Text
            text={unpackedProject?.creator?.ownership?.owner?.email}
            type="md"
            data-test="shipment:text:unknown"
          />
        </div>
        <div className={styles.phone}>
          <Text
            type="label"
            color={'grey'}
            msg={'general.phone'}
            data-test="shipment:text:unknown"
          />
          <Text text={shippingAddress?.phone} type="md" data-test="shipment:text:unknown" />
        </div>
        <div className={styles.address}>
          <Text
            type="label"
            color={'grey'}
            msg={'general.address'}
            data-test="shipment:text:unknown"
          />
          <Text text={fullAddress} type="md" data-test="shipment:text:unknown" />
        </div>
        <div className={styles.controls}>
          <AlterButton
            icon="Chat"
            onClick={handleChatClick}
            type="grey"
            data-test="shipment:alterButton:unknown"
          />
        </div>
      </div>
    </div>
  );
};

export default Shipment;
