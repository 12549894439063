import React, { useMemo, useContext } from 'react';
import { graphql, useFragment } from 'react-relay';
import { useNavigate } from 'react-router-dom';

import styles from './ShipmentInformation.pcss';

import Text from 'Components/ui/Text/Text';
import { ShipmentInformation_project$key } from 'GraphTypes/ShipmentInformation_project.graphql';
import stubImg from 'Images/chat/info-stub.png';
import { shipmentAddressFormatter } from 'Util/shipmentAddressFormatter';
import TextButton from 'Components/ui/TextButton/TextButton';
import { getCampaignShipmentLink } from 'Util/links';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

interface Props {
  project: ShipmentInformation_project$key;
}

const ShipmentInformation: React.FC<Props> = (props) => {
  const { project } = props;

  const data = useFragment(
    graphql`
      fragment ShipmentInformation_project on Project {
        shippingAddress {
          id
          address1
          address2
          city
          country {
            name
            iso2Code
            provinces {
              code
              name
            }
          }
          firstName
          lastName
          phone
          provinceCode
          zip
        }
        launch {
          id
        }
        offer {
          acceptance {
            shippingInformation
            shippingRecipientFirstName
            shippingRecipientLastName
          }
        }
        campaign {
          id
          showShipmentTab
          shopifyAuthorization {
            id
          }
        }
      }
    `,
    project
  );
  const { closeDrawer } = useContext(DrawerContext);

  const navigate = useNavigate();

  const shippingAddress = data.shippingAddress;
  const firstName = shippingAddress?.firstName;
  const lastName = shippingAddress?.lastName;
  const phone = shippingAddress?.phone;
  const address1 = shippingAddress?.address1;
  const address2 = shippingAddress?.address2;
  const city = shippingAddress?.city;
  const zip = shippingAddress?.zip;
  const provinceCode = shippingAddress?.provinceCode;
  const country = shippingAddress?.country;
  const campaignId = data?.campaign?.id;

  const shopifyProjectLink = getCampaignShipmentLink(campaignId);

  const handleClickConnect = () => {
    navigate(shopifyProjectLink);
    closeDrawer('chat-modal');
  };

  const visibleShipmentInformation = useMemo(() => {
    return shipmentAddressFormatter(shippingAddress);
  }, [firstName, lastName, phone, address1, address2, city, zip, provinceCode, country?.name]);

  const shippingInformation = data.offer?.acceptance?.shippingInformation;
  const shippingRecipientFirstName = data.offer?.acceptance?.shippingRecipientFirstName;
  const shippingRecipientLastName = data.offer?.acceptance?.shippingRecipientLastName;

  const info = `${shippingRecipientFirstName} ${shippingRecipientLastName} ${shippingInformation}`;

  const isNewShippingFormat = Boolean(shippingAddress?.id);

  if (!shippingAddress && !shippingInformation) return null;

  const isInfoVisible = !!data.launch?.id;

  return (
    <div className={styles.root}>
      {isInfoVisible ? (
        <Text
          type="md"
          text={isNewShippingFormat ? visibleShipmentInformation : info.trim()}
          className={styles.info}
          data-test="shipmentInformation:text:info"
        />
      ) : (
        <img src={stubImg} />
      )}
      <div className={styles.details}>
        <Text
          type="sm"
          msg="chat.in_modal.shipment_info"
          data-test="shipmentInformation:text:shipmentInfo"
        />
        {!isInfoVisible && (
          <Text
            type="sm"
            msg="chat.in_modal.shipment_info.unlock"
            className={styles.unlockInfo}
            data-test="shipmentInformation:text:unlock"
          />
        )}
      </div>
      {isInfoVisible &&
        data?.campaign?.showShipmentTab &&
        !data?.campaign?.shopifyAuthorization?.id && (
          <TextButton
            msg={'chat.in_modal.shipment_info.button'}
            className={styles.connectButton}
            onClick={handleClickConnect}
            data-test="shipmentInformation:textButton:connectButton"
          />
        )}
    </div>
  );
};

export default ShipmentInformation;
