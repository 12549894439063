import React, { useCallback, useMemo } from 'react';
import { useFragment, graphql } from 'react-relay';
import { useIntl } from 'react-intl';

import styles from './PersonalInfo.pcss';
import ChangePasswordButton from './ChangePasswordButton/ChangePasswordButton';

import { amplitude } from 'Helpers/amplitude';
import updateAdvertiserProfile from 'Mutations/UpdateAdvertiserProfile.Mutation';
import { getCompanyRoleType } from 'AdvertiserPage/AdvertiserSignUp/data';
import Text from 'Components/ui/Text/Text';
import Input from 'Components/ui/Input/Input';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import { PersonalInfo_user$key } from 'GraphTypes/PersonalInfo_user.graphql';

interface Props {
  user: PersonalInfo_user$key;
}

const PersonalInfo: React.FC<Props> = (props) => {
  const { user } = props;
  const data = useFragment(
    graphql`
      fragment PersonalInfo_user on User {
        email
        advertiserProfile {
          id
          lastName
          firstName
          companyRole
        }
      }
    `,
    user
  );

  const intl = useIntl();

  const email = data?.email;
  const advertiserProfileId = data?.advertiserProfile?.id || '';
  const firstName = data?.advertiserProfile?.firstName || '';
  const lastName = data?.advertiserProfile?.lastName || '';
  const companyRole = data?.advertiserProfile?.companyRole;

  const dropdownLabel = companyRole
    ? { text: companyRole }
    : { msg: 'advertiser.settings.personal_info.user_role' };

  const roles = getCompanyRoleType(intl);

  const handleFirstNameChange = useCallback(
    (newFirstName: string) => {
      updateAdvertiserProfile({ id: advertiserProfileId, firstName: newFirstName });
    },
    [advertiserProfileId]
  );

  const handleLastNameChange = useCallback(
    (newLastName: string) => {
      updateAdvertiserProfile({ id: advertiserProfileId, lastName: newLastName });
    },
    [advertiserProfileId]
  );

  const handleFirstNameFocus = useCallback(() => {
    amplitude.sendEvent({
      id: 216,
      category: 'advertiser_settings',
      name: 'personal_settings_name_click',
    });
  }, []);

  const handleLastNameFocus = useCallback(() => {
    amplitude.sendEvent({
      id: 217,
      category: 'advertiser_settings',
      name: 'personal_settings_last_name_click',
    });
  }, []);

  const rolesList = useMemo(() => {
    return roles?.map((item) => {
      if (!item) return null;

      const isActive = item.label === companyRole ? 'Check' : null;

      const handleRoleClick = () => {
        amplitude.sendEvent({
          id: 218,
          category: 'advertiser_settings',
          name: 'personal_settings_user_role_click',
        });
        updateAdvertiserProfile({ id: advertiserProfileId, companyRole: item.label });
      };

      return (
        <DropdownGroupItem key={item.label} handleClick={handleRoleClick}>
          <AlterButton
            msg={item.msg}
            fluid
            iconPosition="right"
            icon={isActive ? 'Check' : null}
            data-test="personalInfo:alterButton:unknown"
          />
        </DropdownGroupItem>
      );
    });
  }, [roles, companyRole, advertiserProfileId]);

  const rolesDropdown = (
    <Dropdown
      value={<Text type="md" {...dropdownLabel} data-test="personalInfo:text:unknown" />}
      className={styles.dropdown}
    >
      <DropdownGroup className={styles.dropdownGroup}>
        <div className={styles.rolesList}>{rolesList}</div>
      </DropdownGroup>
    </Dropdown>
  );

  return (
    <div className={styles.root}>
      <Text
        type="d2"
        msg="advertiser.settings.personal_info.title"
        className={styles.title}
        data-test="personalInfo:text:title"
      />
      <div className={styles.nameWrap}>
        <Input
          labelMsg="advertiser.settings.personal_info.name"
          value={firstName}
          bordered
          hideCloseIcon
          handleChange={handleFirstNameChange}
          onFocus={handleFirstNameFocus}
          className={`${styles.input} ${styles.name}`}
          data-test="personalInfo:input:unknown"
        />
        <Input
          labelMsg="advertiser.settings.personal_info.last_name"
          value={lastName}
          bordered
          hideCloseIcon
          handleChange={handleLastNameChange}
          onFocus={handleLastNameFocus}
          className={`${styles.input} ${styles.name}`}
          data-test="personalInfo:input:unknown"
        />
      </div>
      {rolesDropdown}
      <Input
        labelMsg="advertiser.settings.personal_info.email"
        value={email}
        bordered
        hideCloseIcon
        className={styles.input}
        disabled
        data-test="personalInfo:input:input"
      />
      <ChangePasswordButton email={email} />
    </div>
  );
};

export default PersonalInfo;
