import { IntlShape } from 'react-intl';

export type UserData = {
  firstName: string;
  lastName: string;
  companyRole: string;
  description: string;
  websiteUrl: string;
  name: string;
};

export type UserDataWithExtendFields = UserData & {
  organization: string;
};

export type UserNamesData = {
  firstName: string;
  lastName: string;
  advertiserProfileId?: string;
  companyRole: string;
};

export type CompanyData = {
  websiteUrl: string;
  name: string;
  description?: string;
  industry?: string;
  region?: string;
  source?: string;
  sourceHs?: string;
  verticals: string[];
  goalsHs: string[];
  countryId?: string;
};

export const gaClientId =
  document?.cookie?.match(/_ga=(.+?);/) &&
  document?.cookie
    ?.match(/_ga=(.+?);/)?.[1]
    .split('.')
    .slice(-2)
    .join('.');

export const getCompanyType = (intl?: IntlShape) => {
  return [
    {
      value: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.business_descr.items.ecomm',
          })
        : '',
      label: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.business_descr.items.ecomm',
          })
        : '',
      msg: 'signup_page.advertiser.business_descr.items.ecomm',
      hsValue: 'type_of_business_ecomm',
    },
    {
      value: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.business_descr.items.ecomm_collective',
          })
        : '',
      label: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.business_descr.items.ecomm_collective',
          })
        : '',
      msg: 'signup_page.advertiser.business_descr.items.ecomm_collective',
      hsValue: 'type_of_business_brand_collective',
    },
    {
      value: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.business_descr.items.aggregator',
          })
        : '',
      label: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.business_descr.items.aggregator',
          })
        : '',
      msg: 'signup_page.advertiser.business_descr.items.aggregator',
      hsValue: 'type_of_business_aggregator',
    },
    {
      value: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.business_descr.items.agency',
          })
        : '',
      label: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.business_descr.items.agency',
          })
        : '',
      msg: 'signup_page.advertiser.business_descr.items.agency',
      hsValue: 'type_of_business_agency',
    },
    {
      value: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.business_descr.items.app',
          })
        : '',
      label: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.business_descr.items.app',
          })
        : '',
      msg: 'signup_page.advertiser.business_descr.items.app',
      hsValue: 'type_of_business_mobile_app',
    },
    {
      value: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.business_descr.items.product',
          })
        : '',
      label: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.business_descr.items.product',
          })
        : '',
      msg: 'signup_page.advertiser.business_descr.items.product',
      hsValue: 'type_of_business_digital_service',
    },
    {
      value: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.business_descr.items.other',
          })
        : '',
      label: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.business_descr.items.other',
          })
        : '',
      msg: 'signup_page.advertiser.business_descr.items.other',
      hsValue: 'type_of_business_other',
    },
  ];
};

export const getCompanyCategory = (intl?: IntlShape) => {
  return [
    {
      value: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.industry.items.apparel',
          })
        : '',
      label: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.industry.items.apparel',
          })
        : '',
      msg: 'signup_page.advertiser.industry.items.apparel',
      hsValue: 'industry_fashion',
    },
    {
      value: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.industry.items.beauty',
          })
        : '',
      label: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.industry.items.beauty',
          })
        : '',
      msg: 'signup_page.advertiser.industry.items.beauty',
      hsValue: 'industry_beauty',
    },
    {
      value: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.industry.items.consumer_products',
          })
        : '',
      label: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.industry.items.consumer_products',
          })
        : '',
      msg: 'signup_page.advertiser.industry.items.consumer_products',
      hsValue: 'industry_consumer_goods',
    },
    {
      value: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.industry.items.electronics',
          })
        : '',
      label: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.industry.items.electronics',
          })
        : '',
      msg: 'signup_page.advertiser.industry.items.electronics',
      hsValue: 'industry_electronics',
    },
    {
      value: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.industry.items.food',
          })
        : '',
      label: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.industry.items.food',
          })
        : '',
      msg: 'signup_page.advertiser.industry.items.food',
      hsValue: 'industry_food',
    },
    {
      value: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.industry.items.health',
          })
        : '',
      label: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.industry.items.health',
          })
        : '',
      msg: 'signup_page.advertiser.industry.items.health',
      hsValue: 'industry_health',
    },
    {
      value: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.industry.items.goods',
          })
        : '',
      label: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.industry.items.goods',
          })
        : '',
      msg: 'signup_page.advertiser.industry.items.goods',
      hsValue: 'industry_home',
    },
    {
      value: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.industry.items.jewelry',
          })
        : '',
      label: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.industry.items.jewelry',
          })
        : '',
      msg: 'signup_page.advertiser.industry.items.jewelry',
      hsValue: 'industry_jewelry',
    },
    {
      value: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.industry.items.kids',
          })
        : '',
      label: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.industry.items.kids',
          })
        : '',
      msg: 'signup_page.advertiser.industry.items.kids',
      hsValue: 'industry_kids',
    },
    {
      value: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.industry.items.marketing',
          })
        : '',
      label: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.industry.items.marketing',
          })
        : '',
      msg: 'signup_page.advertiser.industry.items.marketing',
      hsValue: 'industry_marketing',
    },
    {
      value: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.industry.items.pets',
          })
        : '',
      label: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.industry.items.pets',
          })
        : '',
      msg: 'signup_page.advertiser.industry.items.pets',
      hsValue: 'industry_pets',
    },
    {
      value: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.industry.items.services',
          })
        : '',
      label: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.industry.items.services',
          })
        : '',
      msg: 'signup_page.advertiser.industry.items.services',
      hsValue: 'industry_services',
    },
    {
      value: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.industry.items.software',
          })
        : '',
      label: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.industry.items.software',
          })
        : '',
      msg: 'signup_page.advertiser.industry.items.software',
      hsValue: 'industry_dig_service',
    },
    {
      value: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.industry.items.sports',
          })
        : '',
      label: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.industry.items.sports',
          })
        : '',
      msg: 'signup_page.advertiser.industry.items.sports',
      hsValue: 'industry_sports',
    },
    {
      value: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.industry.items.supplements',
          })
        : '',
      label: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.industry.items.supplements',
          })
        : '',
      msg: 'signup_page.advertiser.industry.items.supplements',
      hsValue: 'industry_supplements',
    },
    {
      value: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.industry.items.other',
          })
        : '',
      label: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.industry.items.other',
          })
        : '',
      msg: 'signup_page.advertiser.industry.items.other',
      hsValue: 'industry_other',
    },
  ];
};

export const getRegions = (intl?: IntlShape) => {
  return [
    {
      value: 'US',
      label: intl ? intl.formatMessage({ id: 'country.us' }) : '',
      msg: 'country.us',
      hsValue: 'United States',
    },
    {
      value: 'CA',
      label: intl ? intl.formatMessage({ id: 'country.ca' }) : '',
      msg: 'country.ca',
      hsValue: 'Canada',
    },
    {
      value: 'GB',
      label: intl ? intl.formatMessage({ id: 'country.gb' }) : '',
      msg: 'country.gb',
      hsValue: 'United Kingdom',
    },
    {
      value: 'AU',
      label: intl ? intl.formatMessage({ id: 'country.au' }) : '',
      msg: 'country.au',
      hsValue: 'Australia',
    },
    {
      value: 'DE',
      label: intl ? intl.formatMessage({ id: 'country.deu' }) : '',
      msg: 'country.deu',
      hsValue: 'Germany',
    },
    {
      value: 'FR',
      label: intl ? intl.formatMessage({ id: 'country.fr' }) : '',
      msg: 'country.fr',
      hsValue: 'France',
    },
    {
      value: 'OTHER',
      label: intl
        ? intl.formatMessage({
            id: 'country.other',
          })
        : '',
      msg: 'country.other',
      hsValue: 'Other',
    },
  ];
};

export const getCompanyRoleType = (intl?: IntlShape) => {
  return [
    {
      value: intl ? intl.formatMessage({ id: 'signup_page.advertiser.role.items.ceo' }) : '',
      label: intl ? intl.formatMessage({ id: 'signup_page.advertiser.role.items.ceo' }) : '',
      msg: 'signup_page.advertiser.role.items.ceo',
      hsValue: 'CEO/Owner',
    },
    {
      value: intl ? intl.formatMessage({ id: 'signup_page.advertiser.role.items.cmo' }) : '',
      label: intl ? intl.formatMessage({ id: 'signup_page.advertiser.role.items.cmo' }) : '',
      msg: 'signup_page.advertiser.role.items.cmo',
      hsValue: 'Marketing Executive/CMO',
    },
    {
      value: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.role.items.dig_marketing',
          })
        : '',
      label: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.role.items.dig_marketing',
          })
        : '',
      msg: 'signup_page.advertiser.role.items.dig_marketing',
      hsValue: 'Digital Marketing',
    },
    {
      value: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.role.items.media_buying',
          })
        : '',
      label: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.role.items.media_buying',
          })
        : '',
      msg: 'signup_page.advertiser.role.items.media_buying',
      hsValue: 'Media Buyer/Growth Marketing/Performance Marketing',
    },
    {
      value: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.role.items.influencer_manager',
          })
        : '',
      label: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.role.items.influencer_manager',
          })
        : '',
      msg: 'signup_page.advertiser.role.items.influencer_manager',
      hsValue: 'Influencer Marketing',
    },
    {
      value: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.role.items.creative_strategist',
          })
        : '',
      label: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.role.items.creative_strategist',
          })
        : '',
      msg: 'signup_page.advertiser.role.items.creative_strategist',
      hsValue: 'Creative',
    },
    {
      value: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.role.items.operations',
          })
        : '',
      label: intl
        ? intl.formatMessage({
            id: 'signup_page.advertiser.role.items.operations',
          })
        : '',
      msg: 'signup_page.advertiser.role.items.operations',
      hsValue: 'Ops/Assistant/Associate',
    },
    {
      value: intl ? intl.formatMessage({ id: 'signup_page.advertiser.role.items.other' }) : '',
      label: intl ? intl.formatMessage({ id: 'signup_page.advertiser.role.items.other' }) : '',
      msg: 'signup_page.advertiser.role.items.other',
      hsValue: 'Other',
    },
  ];
};

export const getSources = (intl?: IntlShape) => {
  if (!intl) return [];

  return [
    {
      text: intl.formatMessage({
        id: 'signup_page.advertiser.source.items.word_mouth',
      }),
      hsValue: 'word_mouth',
    },
    {
      text: intl.formatMessage({
        id: 'signup_page.advertiser.source.items.search_engine',
      }),
      hsValue: 'search_engine',
    },
    {
      text: intl.formatMessage({
        id: 'signup_page.advertiser.source.items.linkedIn',
      }),
      hsValue: 'LinkedIn',
    },
    {
      text: intl.formatMessage({
        id: 'signup_page.advertiser.source.items.facebook',
      }),
      hsValue: 'facebook',
    },
    {
      text: intl.formatMessage({
        id: 'signup_page.advertiser.source.items.blog',
      }),
      hsValue: 'blog',
    },
    {
      text: intl.formatMessage({
        id: 'signup_page.advertiser.source.items.newsletter',
      }),
      hsValue: 'newsletter',
    },
    {
      text: intl.formatMessage({
        id: 'signup_page.advertiser.source.items.event',
      }),
      hsValue: 'event',
    },
    {
      text: intl.formatMessage({
        id: 'signup_page.advertiser.source.items.team',
      }),
      hsValue: 'team',
    },
    {
      text: intl.formatMessage({ id: 'signup_page.advertiser.items.other' }),
      commentRequired: true,
      hsValue: 'source_other',
      commentPlaceholderMsg: 'signup_page.advertiser.items.other.placeholder',
    },
  ];
};
