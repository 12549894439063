import compact from 'lodash/compact';

import { StoriesStatistic_creator$data } from 'GraphTypes/StoriesStatistic_creator.graphql';
import { createShortNumber } from 'Util/numberFormatter';

export const createData = (data: StoriesStatistic_creator$data) => {
  const userInsightsData = data.userInsightsData;

  const stories = userInsightsData?.stories;
  const storiesAverageReach = userInsightsData?.storiesAverageReach;
  const storiesAverageImpressions = userInsightsData?.storiesAverageImpressions;

  const avgReach = Number(storiesAverageReach) < 0 ? 0 : storiesAverageReach;
  const avgImpression = Number(storiesAverageImpressions) < 0 ? 0 : storiesAverageImpressions;

  return compact([
    {
      title: 'creator_statistic.subtitles.stories',
      tooltipMsg: 'creator_statistic.subtitles.stories.tooltip',
      value: createShortNumber(stories),
    },
    {
      title: 'creator_statistic.subtitles.stories.reach',
      tooltipMsg: 'creator_statistic.subtitles.stories.reach.tooltip',
      value: createShortNumber(avgReach),
    },
    {
      title: 'creator_statistic.subtitles.stories.impressions',
      tooltipMsg: 'creator_statistic.subtitles.stories.impressions.tooltip',
      value: createShortNumber(avgImpression),
    },
  ]);
};
