import React, { useState, useContext, useMemo } from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import track from 'react-tracking';
import { useNavigate, useParams, useLocation, matchPath } from 'react-router-dom';
import camelCase from 'lodash/camelCase';
import compact from 'lodash/compact';
import xor from 'lodash/xor';

import styles from './CreatorProfile.pcss';
import PortfolioManager from './PortfolioManager/PortfolioManager';
import CreatorBirthdate from './CreatorBirthdate/CreatorBirthdate';
import CreatorCategories from './CreatorCategories/CreatorCategories';

import environment from 'Api/Environment';
import { getCreatorData } from 'Util/creatorData';
import { sortCountries } from 'Util/dataFormatter';
import updateCreatorProfile from 'Mutations/UpdateCreatorProfile.Mutation';
import updateContractorProfile from 'Mutations/UpdateContractorProfile.Mutation';
import AvatarWithBadge from 'Molecules/AvatarWithBadge/AvatarWithBadge';
import CreatorCities from 'Molecules/CreatorCities/CreatorCities';
import Avatar from 'Atoms/Avatar/Avatar';
import Page from 'Templates/Page/Page';
import Text from 'Atoms/Text/Text';
import IconOld from 'Atoms/Icon/Icon';
import Checkbox from 'Atoms/Checkbox/Checkbox';
import TextButton from 'Atoms/TextButton/TextButton';
import Button from 'Atoms/Button/Button';
import Field from 'Molecules/Field/Field';
import Warning from 'Atoms/Warning/Warning';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import ProgressiveInput from 'Components/ProgressiveInput/ProgressiveInput';
import { CreatorProfileQuery as QueryType } from 'GraphTypes/CreatorProfileQuery.graphql';
import { modalName } from 'Types/modals';
import {
  RUB,
  DASHBOARD_ROUTE,
  BADGE_INFO,
  MAX_CATEGORIES_AMOUNT,
  PROJECT_FOR_OFFER,
} from 'Constants/general';
import type { UpdateContractorProfileInput } from 'GraphTypes/UpdateContractorProfileMutation.graphql';
import useDashly from 'Hooks/useDashly';
import { IconType } from 'Types/common';
import { ModalContext, Types as ModalTypes } from 'Containers/ModalContainer/ModalContainerContext';

const CreatorProfileQuery = graphql`
  query CreatorProfileQuery($id: ID!) {
    creator(id: $id) {
      id
      username
      ownership {
        owner {
          paymentAccount {
            currency
          }
          contractorProfile {
            id
            firstName
            languages {
              id
              name
            }
          }
        }
      }
      type
      ... on YoutubeCreator {
        channel {
          name
        }
      }
      review {
        type
      }
      profile {
        id
        acceptsBarter
        birthdate
        badge
        currency
        profilePictureFile {
          url
          ... on Image {
            transformations {
              collageThumbnailUrl
            }
          }
        }
        categories {
          id
          name
        }
        cities {
          id
          name
        }
        customPrice
        gender {
          id
        }
        ethnicity {
          id
          name
        }
        ...CreatorBirthdate_birthdate
      }
      ...PortfolioManager_creator
    }
    usdGenders: genders(currency: USD) {
      id
      name
    }
    ethnicities {
      id
      name
    }
    languages {
      id
      name
    }
    usdCategories: categories(currency: USD) {
      id
      name
      description
    }
    settings {
      maxMandatoryCategoriesCount
    }
    currentUser {
      id
      contractorProfile {
        id
        firstName
        lastName
        country {
          id
        }
        languages {
          id
          name
        }
      }
    }
    countries {
      id
      name
      iso2Code
    }
  }
`;

interface Props {
  className?: string;
  id?: string;
  disableRedirect?: boolean;
  hiddenContractorProfileFields?: boolean;
  onDoneClick?: () => void;
  hideStub?: boolean;
}

const CreatorProfile: React.FC<Props> = (props) => {
  const { className, id, disableRedirect, hiddenContractorProfileFields, onDoneClick, hideStub } =
    props;

  const { dispatch: modalDispatch } = useContext(ModalContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { open: openDashly } = useDashly();
  const { id: creatorId } = useParams<{ id?: string }>();

  const finalId = id || creatorId;

  if (!finalId) return null;

  const [contractorProfileData, setContractorProfileData] = useState<
    Partial<UpdateContractorProfileInput>
  >({
    firstName: '',
    lastName: '',
    countryId: '',
  });

  const routeData = location.state;

  let profileId: undefined | string;
  let contractorProfileId: undefined | string;
  let fieldsForAcceptView: undefined | boolean;

  const handleContractorProfileChange = (data: UpdateContractorProfileInput) => {
    setContractorProfileData((prev) => {
      return { ...prev, ...data };
    });
  };

  const handleCountryChange = (newCountryId: string) => {
    setContractorProfileData((prev) => {
      return { ...prev, countryId: newCountryId };
    });
  };

  const handleChange = ({ customPrice }: { customPrice: string }) => {
    if (!profileId) return;
    updateCreatorProfile({ id: profileId, customPrice: parseInt(customPrice, 10) });
  };

  const handleCityListChange = (newCityIds: string[]) => {
    if (!profileId) return;
    updateCreatorProfile({ id: profileId, cityIds: newCityIds });
  };

  const handleBirthdateChange = (data: { birthdate: Date | null }) => {
    if (!profileId) return;

    updateCreatorProfile({ id: profileId, ...data });
  };

  const handleGenderChange = (newGender: string) => {
    if (!profileId) return;
    updateCreatorProfile({ id: profileId, genderId: newGender });
  };

  const handleEthnicityChange = (newEthnicity: string) => {
    if (!profileId) return;
    updateCreatorProfile({ id: profileId, creatorEthnicityId: newEthnicity });
  };

  const handleCategoriesChange = (ids: string[]) => {
    if (!profileId) return;

    const newCategoriesList = ids || [];

    updateCreatorProfile({ id: profileId, categoryIds: newCategoriesList });
  };

  const handleDashlyClick = () => {
    openDashly();
  };

  return (
    <Page className={`${className} ${styles.root}`}>
      <div className={styles.content}>
        <QueryRenderer<QueryType>
          environment={environment}
          query={CreatorProfileQuery}
          variables={{ id: finalId }}
          render={({ props: queryProps }) => {
            if (!queryProps) return null;
            const {
              creator,
              usdGenders,
              ethnicities,
              languages,
              usdCategories,
              settings,
              currentUser,
              countries,
            } = queryProps;

            if (!creator) return null;

            const { avatarUrl, username, iconColored } = getCreatorData(creator);

            const { profile } = creator;

            const firstName =
              contractorProfileData.firstName || currentUser?.contractorProfile?.firstName;
            const lastName =
              contractorProfileData.lastName || currentUser?.contractorProfile?.lastName;
            const countryId =
              contractorProfileData.countryId || currentUser?.contractorProfile?.country?.id;
            const creatorId = creator.id;
            const id = profile?.id;
            const acceptsBarter = profile?.acceptsBarter;
            const birthdate = profile?.birthdate;
            const badge = profile?.badge;
            const customPrice = profile?.customPrice;
            const cities = profile?.cities || [];
            const review = creator?.review;

            const userCurrency = creator.ownership?.owner.paymentAccount?.currency;

            const maxCategoriesToChoose =
              settings?.maxMandatoryCategoriesCount || MAX_CATEGORIES_AMOUNT;

            profileId = id;
            contractorProfileId = currentUser?.contractorProfile?.id;

            const allCategories = usdCategories;

            const creatorGender = creator.profile?.gender?.id;
            const creatorEthnicity = creator.profile?.ethnicity?.id;
            const creatorLanguages = currentUser?.contractorProfile?.languages;
            const creatorCategories = profile?.categories || [];
            const contractorLanguages = creator?.ownership?.owner?.contractorProfile?.languages;

            const countriesOptions = compact(
              sortCountries(countries).map((item) => {
                const { name, iso2Code, id } = item;
                if (iso2Code === 'RU') return null;
                return {
                  value: name,
                  id,
                  isChecked: countryId === id,
                };
              })
            );

            const gendersList = usdGenders.map((item) => {
              return { value: item.name, id: item.id, isChecked: item.id === creatorGender };
            });

            const creatorLanguageIds = contractorLanguages?.map((item) => item.id);

            const contractorLanguagesList = languages?.map((item) => {
              const languageId = item.id;
              return {
                id: languageId,
                label: item.name,
                isChecked: creatorLanguageIds?.includes(languageId) || false,
              };
            });

            const ethnicitiesList = ethnicities.map((item) => {
              return { value: item.name, id: item.id, isChecked: item.id === creatorEthnicity };
            });

            const creatorCategoriesList = creatorCategories.map((item) => {
              return { value: item.id, label: item.name };
            });

            const categoriesList = allCategories.map((item) => {
              const isActive = creatorCategories.find(
                (selectedItem) => selectedItem.id === item.id
              );
              const isDisabled = !isActive && creatorCategories.length >= maxCategoriesToChoose;
              const optionText = (
                <div className={styles.option}>
                  <div className={styles.optionName}>{item.name}</div>
                  {item.description && <div className={styles.optionDescr}>{item.description}</div>}
                </div>
              );

              return {
                value: item.id,
                id: item.id,
                text: optionText,
                label: item.name,
                leftIcon: <Checkbox active={!!isActive} />,
                disableNativeIcon: true,
                isDisabled: isDisabled,
              };
            });

            const checkNeedAccept = () => {
              return (
                (!hiddenContractorProfileFields && (!firstName || !lastName || !countryId)) ||
                !creatorGender ||
                !birthdate ||
                cities.length === 0 ||
                creatorCategories.length === 0 ||
                (userCurrency === 'USD' && (creatorLanguages?.length === 0 || !creatorEthnicity))
              );
            };

            if (fieldsForAcceptView === undefined) {
              const isProjectOfferPage = Boolean(matchPath(PROJECT_FOR_OFFER, location.pathname));
              fieldsForAcceptView = isProjectOfferPage || checkNeedAccept();
            }

            const handleAcceptsBarterClick = () => {
              if (!profileId) return;
              updateCreatorProfile({ id: profileId, acceptsBarter: !acceptsBarter });
            };

            const handleDoneClick = () => {
              if (!hiddenContractorProfileFields) {
                updateContractorProfile({
                  ...contractorProfileData,
                  id: contractorProfileId || '',
                });
              }

              if (routeData?.listingId) {
                modalDispatch({
                  type: ModalTypes.SET_MODAL,
                  payload: {
                    name: modalName.ACCEPT_OFFER,
                    attach: { listingId: routeData.listingId, creatorId },
                  },
                });
                onDoneClick?.();
                return;
              }

              onDoneClick?.();

              if (!disableRedirect) {
                navigate(DASHBOARD_ROUTE);
              }
            };

            const handleLanguagesChange = (newLanguage: any) => {
              if (contractorProfileId) {
                const newList = xor(creatorLanguageIds, [newLanguage]);
                updateContractorProfile({ id: contractorProfileId, languageIds: newList });
              }
            };

            const handleLanguagesClear = () => {
              if (contractorProfileId) {
                updateContractorProfile({ id: contractorProfileId, languageIds: [] });
              }
            };

            const titleData = fieldsForAcceptView
              ? { msg: 'creator_profile.enter_info', values: { username } }
              : { text: username };

            if (!fieldsForAcceptView && routeData?.listingId) {
              modalDispatch({
                type: ModalTypes.SET_MODAL,
                payload: {
                  name: modalName.ACCEPT_OFFER,
                  attach: { listingId: routeData.listingId, creatorId },
                },
              });
            }

            return (
              <div className={styles.container}>
                {fieldsForAcceptView && !hideStub && <div className={styles.stub} />}
                <div className={styles.avaWrap}>
                  <div className={styles.ava}>
                    <AvatarWithBadge
                      badge={badge}
                      withIcon={false}
                      avaData={{ size: 180, src: avatarUrl }}
                    />
                    {iconColored && (
                      <div className={styles.icon}>
                        <IconOld name={iconColored} />
                      </div>
                    )}
                  </div>
                  {badge && (
                    <SmartLink path={BADGE_INFO} target="_blank" className={styles.badgeWrap}>
                      <Tooltip id="badge" tooltipMsg="creator_profile.badge.tooltip" place="top">
                        <div className={styles.badge}>
                          <IconOld
                            name={camelCase(badge) as IconType['name']}
                            className={styles.badgeIcon}
                          />
                          <Text msg={`creator.badge.${badge.toLowerCase()}`} />
                        </div>
                      </Tooltip>
                    </SmartLink>
                  )}
                  {!review && (
                    <div className={styles.warning}>
                      <Warning hasIcon={false} msg="creator_profile.not_reviewed" />
                    </div>
                  )}
                </div>
                <div className={styles.form}>
                  <Text tag="h1" weight="700" {...titleData} className={styles.title} />
                  {!hiddenContractorProfileFields && (
                    <>
                      <div className={styles.row}>
                        <Field
                          required
                          element="input"
                          elementData={{
                            defaultValue: firstName,
                            onChange: handleContractorProfileChange,
                          }}
                          name="firstName"
                          title="creator_profile.first_name"
                          className={styles.field}
                        />
                        <Field
                          required
                          element="input"
                          elementData={{
                            defaultValue: lastName,
                            onChange: handleContractorProfileChange,
                          }}
                          name="lastName"
                          title="creator_profile.last_name"
                          className={styles.field}
                        />
                      </div>
                      <ProgressiveInput
                        type="radio"
                        titleMsg="form.country"
                        className={styles.fieldItem}
                        inputProps={{
                          items: countriesOptions,
                          bordered: true,
                          handleClick: handleCountryChange,
                          withArrow: true,
                        }}
                        isDirty={!!countryId}
                      />
                    </>
                  )}
                  <div className={styles.row}>
                    <ProgressiveInput
                      type="radio"
                      titleMsg="creator.gender"
                      className={styles.field}
                      inputProps={{
                        items: gendersList,
                        bordered: true,
                        handleClick: handleGenderChange,
                        hideByAlphabet: true,
                        withArrow: true,
                      }}
                      isDirty={!!creatorGender}
                    />
                    <CreatorBirthdate profile={profile} onChange={handleBirthdateChange} />
                  </div>
                  <CreatorCities cities={cities} onCitiesChange={handleCityListChange} />
                  <ProgressiveInput
                    type="radio"
                    titleMsg="creator.ethnicity"
                    className={styles.row}
                    inputProps={{
                      items: ethnicitiesList,
                      bordered: true,
                      handleClick: handleEthnicityChange,
                      withArrow: true,
                    }}
                    isDirty={!!creatorEthnicity}
                  />
                  <ProgressiveInput
                    type="checkboxSelect"
                    titleMsg="creator.language"
                    className={styles.fieldItem}
                    inputProps={{
                      limitForShow: 6,
                      items: contractorLanguagesList,
                      bordered: true,
                      handleClick: handleLanguagesChange,
                      handleClear: handleLanguagesClear,
                      withArrow: true,
                    }}
                    isDirty={Number(contractorLanguages?.length) > 0}
                  />
                  <div className={styles.categories}>
                    <CreatorCategories
                      allCategories={allCategories}
                      creatorCategories={creatorCategories}
                      maxCategoriesToChoose={maxCategoriesToChoose}
                      onChange={handleCategoriesChange}
                    />
                  </div>
                  <div className={styles.categoriesRequest}>
                    <Text
                      size="xs"
                      color="grayDog"
                      msg="creator_profile.categories.change_request"
                    />
                    <TextButton
                      size="sm"
                      msg="creator_profile.categories.contact_support"
                      onClick={handleDashlyClick}
                    />
                  </div>
                  {!fieldsForAcceptView && (
                    <div>
                      <div className={styles.row}>
                        <Field
                          element="input"
                          elementData={{
                            defaultValue: customPrice,
                            onBlur: handleChange,
                          }}
                          name="customPrice"
                          title="creator_profile.integration"
                          className={styles.field}
                        />
                      </div>
                      <div className={styles.barter}>
                        <TextButton
                          icon={<Checkbox active={!!acceptsBarter} />}
                          onClick={handleAcceptsBarterClick}
                        />
                        <div className={styles.barterDesrc}>
                          <Text weight="500" msg="creator_profile.barter_title" />
                          <Text color="grayDog" msg="creator_profile.barter_descr" />
                        </div>
                      </div>
                    </div>
                  )}
                  {!fieldsForAcceptView && <PortfolioManager creator={creator} />}
                  {fieldsForAcceptView && (
                    <Button
                      width="full"
                      size="lg"
                      msg="creator_profile.done"
                      disabled={checkNeedAccept()}
                      className={styles.done}
                      onClick={handleDoneClick}
                    />
                  )}
                </div>
              </div>
            );
          }}
        />
      </div>
    </Page>
  );
};

export default track(
  {
    page: 'creator_profile',
  },
  { dispatchOnMount: true }
)(CreatorProfile);
