import React from 'react';
import * as Scroll from 'react-scroll';
const Element = Scroll.Element;

import styles from './StatisticContainer.pcss';
import CreatorStatsTabs from './CreatorStatsTabs/CreatorStatsTabs';
import CreatorInfo from './CreatorInfo/CreatorInfo';
import AudienceInfo from './AudienceInfo/AudienceInfo';
import CreatorReviews from './CreatorReviews/CreatorReviews';
import CreatorAdminPanel from './CreatorAdminPanel/CreatorAdminPanel';
import PostsStatistic from './PostsStatistic/PostsStatistic';
import StoriesStatistic from './StoriesStatistic/StoriesStatistic';
import CreatorModeration from './CreatorModeration/CreatorModeration';

import Text from 'Components/ui/Text/Text';
import { CreatorStatisticQuery$data } from 'GraphTypes/CreatorStatisticQuery.graphql';
import { Currency } from 'GraphTypes/MediaplanItemInfoQuery.graphql';

interface Props {
  creatorId: string;
  admin?: boolean;
  creator: CreatorStatisticQuery$data['creator'];
  price?: number | null;
  currency?: Currency | null;
  permissions?: NonNullable<CreatorStatisticQuery$data['currentUser']>['permissions'] | null;
}

const StatisticContainer: React.FC<Props> = (props) => {
  const { creatorId, creator, admin, price, permissions } = props;

  const userInsightsData = creator?.userInsightsData?.id;
  const currency = creator?.profile?.currency;

  if (!creator) return null;

  return (
    <div>
      <CreatorStatsTabs creator={creator} admin={admin} />
      <Element name="creator">
        <CreatorInfo price={price} currency={currency} creator={creator} admin={admin} />
      </Element>
      <Element name="audience">
        <AudienceInfo creator={creator} />
      </Element>
      {userInsightsData && (
        <Element name="posts">
          <PostsStatistic creator={creator} />
        </Element>
      )}
      {userInsightsData && (
        <Element name="stories">
          <StoriesStatistic creator={creator} />
        </Element>
      )}
      <CreatorReviews admin={admin} creatorId={creatorId} />
      {admin && permissions?.includes('CREATOR_PROFILES__EDIT') && (
        <Element name="settings">
          <CreatorAdminPanel creatorId={creatorId} currency={currency} />
        </Element>
      )}
      {permissions?.includes('CREATOR_PROFILES__MODERATE') && !admin && (
        <div>
          <Text
            type="d1"
            msg="creator_statistic.tab_settings"
            className={styles.title}
            data-test="statisticContainer:text:tabSettings"
          />
          <div className={styles.moderatorSettings}>
            <CreatorModeration
              creatorId={creatorId}
              profileId={creator?.profile?.id}
              searchable={creator?.profile?.searchable}
              reviewType={creator?.review?.type}
              reviewSource={creator?.review?.source}
              reviewReason={creator?.review?.reason}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default StatisticContainer;
