import React from 'react';
import { createPaginationContainer, graphql, RelayPaginationProp } from 'react-relay';

import styles from './MediaplanItems.pcss';
import MediaplanItem from './MediaplanItem/MediaplanItem';

import LoaderHandler from 'Containers/LoaderHandler/LoaderHandler';
import Text from 'Atoms/Text/Text';
import { AdvertiserProjectStage } from 'GraphTypes/MediaplanSectionQuery.graphql';
import { MediaplanItems_campaign$data } from 'GraphTypes/MediaplanItems_campaign.graphql';

const PAGE_SIZE = 20;
const SCROLL_OFFSET = 300;

interface Props {
  className?: string;
  relay: RelayPaginationProp;
  campaignId: string;
  admin?: boolean;
  createdByAdmin?: boolean | null;
  campaign: MediaplanItems_campaign$data;
  stages?: AdvertiserProjectStage[];
  canViewCreatorPrices?: boolean;
  isBlockedView?: boolean;
  showUnlockCreatorBanner: () => void;
}

const MediaplanItems: React.FC<Props> = (props) => {
  const {
    className,
    relay,
    campaignId,
    admin,
    isBlockedView,
    campaign: { projects },
    canViewCreatorPrices,
    showUnlockCreatorBanner,
  } = props;

  const renderMediaplanItems = () => {
    const { edges } = projects;

    if (!edges || edges.length === 0) {
      return (
        <div className={styles.emptyList}>
          <Text
            color="grayDog"
            msg="browse_creators.mediaplan_list_empty"
            data-test="mediaplanItems:text:mediaplanListEmpty"
          />
        </div>
      );
    }

    return edges.map((item) => {
      if (!item || !item.node) return null;

      const { id } = item.node;

      return (
        <MediaplanItem
          key={id}
          campaignId={campaignId}
          className={styles.item}
          project={item.node}
          admin={admin}
          isBlockedView={isBlockedView}
          canViewCreatorPrices={canViewCreatorPrices}
          showUnlockCreatorBanner={showUnlockCreatorBanner}
        />
      );
    });
  };

  return (
    <div className={`${className} ${styles.root}`} id="mediaplanItems">
      <LoaderHandler
        relay={relay}
        count={PAGE_SIZE}
        ownContainer={true}
        containerId="mediaplanItems"
        offset={SCROLL_OFFSET}
        preloaderStyle={styles.preloader}
      >
        <div className={styles.list}>{renderMediaplanItems()}</div>
      </LoaderHandler>
    </div>
  );
};

export default createPaginationContainer(
  MediaplanItems,
  {
    campaign: graphql`
      fragment MediaplanItems_campaign on Campaign
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 20 }
        cursor: { type: "String" }
        archived: { type: "Boolean" }
        stages: { type: "[AdvertiserProjectStage!]" }
        createdByAdmin: { type: "Boolean" }
      ) {
        id
        projects(
          first: $count
          after: $cursor
          archived: $archived
          stages: $stages
          createdByAdmin: $createdByAdmin
        ) @connection(key: "Mediaplan_projects", filters: []) {
          edges {
            node {
              id
              creator {
                username
              }
              ...MediaplanItem_project
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.campaign && props.campaign.projects;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
        campaignId: props.campaign.id,
        stages: props.stages,
        createdByAdmin: props.createdByAdmin,
      };
    },
    query: graphql`
      query MediaplanItemsPaginationQuery(
        $campaignId: ID!
        $count: Int!
        $cursor: String
        $archived: Boolean
        $createdByAdmin: Boolean
        $stages: [AdvertiserProjectStage!]
      ) {
        campaign(id: $campaignId) {
          ...MediaplanItems_campaign
            @arguments(
              count: $count
              cursor: $cursor
              archived: $archived
              stages: $stages
              createdByAdmin: $createdByAdmin
            )
        }
      }
    `,
  }
);
