import React, { ReactNode } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import classnames from 'classnames';

import styles from './Button.pcss';

import modsClasses from 'Util/modsClasses.js';
import Spinner from 'Atoms/Spinner/Spinner';
import Icon from 'Atoms/Icon/Icon';
import colors from 'Styles/vars/colors.css';
import { buttonColor, buttonTheme, ButtonType } from 'Types/common';

const MODS = ['size', 'color', 'theme', 'mainColor', 'width', 'radius', 'contentAlign', 'border'];

const getPreloaderColor = (color?: buttonColor, theme?: buttonTheme) => {
  switch (color) {
    case 'secondary':
      return colors.colorBase;
    case 'normal':
      return theme === 'dark' ? colors.colorWhite : colors.colorWetAsphalt;
    default:
      return colors.colorWhite;
  }
};

const Button: React.FC<ButtonType & WrappedComponentProps> = (props) => {
  const {
    intl,
    msg,
    msgValues = undefined,
    type = 'button',
    text,
    icon,
    iconName,
    leftIcon,
    leftIconName,
    rightIcon,
    rightIconName,
    loading,
    disabled,
    style,
    onClick,
    dataTest,
  } = props;

  const getTextContent = () => {
    let textValue: string | ReactNode[] = '';

    if (msg) {
      textValue = intl.formatMessage({ id: msg }, msgValues);
    } else if (text) {
      textValue = text;
    }

    return textValue ? <span className={styles.text}>{textValue}</span> : null;
  };

  const textContent = getTextContent();

  const getContent = () => {
    return icon || iconName ? (
      <div className={styles.icon}>{iconName ? <Icon name={iconName} /> : icon}</div>
    ) : (
      <div className={styles.content} data-test="content">
        {leftIcon}
        {leftIconName && <Icon name={leftIconName} />}
        {textContent}
        {rightIconName && <Icon name={rightIconName} />}
        {rightIcon}
      </div>
    );
  };

  const getPreloader = () => {
    const { color, theme } = props;
    const preloaderColor = getPreloaderColor(color, theme);

    return <Spinner className={styles.preloader} color={preloaderColor} />;
  };

  const classes = modsClasses(MODS, props, styles);
  const classNameList = classnames(classes, styles.root, {
    [styles.loading]: loading,
    [styles.disabled]: disabled,
    [styles.withContent]: textContent,
  });

  const handleClick = disabled || loading ? undefined : onClick;

  return (
    <button
      type={type}
      style={style}
      className={classNameList}
      onClick={handleClick}
      data-test={dataTest}
    >
      {loading && getPreloader()}
      {getContent()}
    </button>
  );
};

Button.defaultProps = {
  theme: 'light',
  size: 'md',
  color: 'primary',
  mainColor: 'base',
} as Partial<ButtonType>;

export default injectIntl(Button);
