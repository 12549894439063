import { Filters } from './WithdrawalFilters/WithdrawalFilters';

import { createEndOfDay } from 'Util/dateCreator';

const getWarningLevels = (item?: string[]) => {
  if (!item || item.length === 0) return undefined;

  const itemValue = item[0];

  switch (itemValue) {
    case 'WITH_FORMS':
      return ['HIGH', 'MEDIUM', 'LOW'];
    case 'NO_FORMS':
      return ['HIGH'];
    case 'NEED_TO_CHECK':
      return ['MEDIUM'];
    case 'CHECKED_ONLY':
      return ['LOW'];

    default:
      return undefined;
  }
};

export const getDataForSearch = (filters: Filters) => {
  return {
    ...filters,
    dateFrom: filters.dateFrom ? createEndOfDay(filters.dateFrom) : null,
    dateTo: filters.dateTo ? createEndOfDay(filters.dateTo) : null,
    accountQuery: filters.accountQuery.trim(),
    profileQuery: filters.profileQuery.trim(),
    paymentDataQuery: filters.paymentDataQuery.trim(),
    campaignQuery: filters.campaignQuery.trim(),
    w9WarningLevels: getWarningLevels(filters.w9WarningLevels),
  };
};
