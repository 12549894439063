import React, { useContext, useEffect } from 'react';
import { graphql, useFragment } from 'react-relay';
import { Element } from 'react-scroll';

import { prepareCampaignValue } from '../../../utils';
import { BriefContext } from '../../../Brief.Context';
import Field from '../components/Field/Field';
import Section from '../components/Section/Section';
import SegmentedControls from '../components/SegmentedControls/SegmentedControls';
import With from '../components/With/With';

import ProductSeedingDetails from './ProductSeedingDetails/ProductSeedingDetails';
import styles from './CampaignDetails.pcss';
import { checkValidation, getObjectivesList, getPlatformsList, getViewConditions } from './util';
import BrandDetails from './BrandDetails/BrandDetails';
import SparkAdsDetails from './SparkAdsDetails/SparkAdsDetails';
import PartnershipAdsDetails from './PartnershipAdsDetails/PartnershipAdsDetails';
import ComissionRateDetails from './ComissionRateDetails/ComissionRateDetails';
import HiringGoal from './HiringGoal/HiringGoal';
import UseCase from './UseCase/UseCase';
import WlSlotsBanner from './WlSlotsBanner/WlSlotsBanner';

import { amplitude } from 'Helpers/amplitude';
import { addHubspotScript, identifyUser } from 'Analytics/engines/Hubspot';
import updateBrief from 'Mutations/UpdateBrief.Mutation';
import updateCampaign from 'Mutations/UpdateCampaign.Mutation';
import {
  CampaignDetails_campaign$data,
  CampaignDetails_campaign$key,
  CampaignPlatform,
} from 'GraphTypes/CampaignDetails_campaign.graphql';
import {
  CONTENT_CREATION,
  CREATOR_ADS,
  INFLUENCER,
  ORGANIC_POSTING,
  TIKTOK,
} from 'Constants/general';
import type { UpdateCampaignInput } from 'GraphTypes/UpdateCampaignMutation.graphql';
import type { UpdateBriefInput } from 'GraphTypes/UpdateBriefMutation.graphql';
import { FeatureFlagsContext } from 'Containers/FeatureFlags/FeatureFlags.Context';

interface Props {
  campaign: CampaignDetails_campaign$key;
  isAdmin: boolean;
  isOwner: boolean;
}

const CampaignDetails: React.FC<Props> = (props) => {
  const [briefState] = useContext(BriefContext);

  const { showErrors, shownElementsWithPossibleError } = briefState;

  const { hasFeatureFlag } = useContext(FeatureFlagsContext);

  const tiktokShopAvailable = hasFeatureFlag('tiktok_shop');

  const [{ availableCampaignTypes, planId }, briefDispatch] = useContext(BriefContext);

  const { isAdmin, isOwner, campaign } = props;

  const data = useFragment(
    graphql`
      fragment CampaignDetails_campaign on Campaign {
        useCasesUnlocked
        id
        name
        type
        postingType
        platform
        paymentType
        objective
        useCase
        brand {
          id
        }
        organization {
          test
          id
          paying
          currency
          trialMonthPlanAvailable
          tiktokShopLinked
          counters {
            bcaLicensedCreators
            psLicensedCreators
          }
          subscription {
            planId
            effectiveLimits {
              maxLicensedCreators
              maxPaidSocialActivationDays
            }
          }
        }
        ...WlSlotsBanner_campaign
        brief {
          id
          ...PartnershipAdsDetails_brief
          ...HiringGoal_brief
          ... on V2Brief {
            creatives {
              edges {
                node {
                  id
                }
              }
            }
            includeReferralFee
            referralFeeRate
            paidSocial
            paidSocialActivationDays
            paidSocialBca
            creatorsCountFrom
            creatorsCountTo
            sparkAdsActivationDays
            priceLimitMin
            priceLimitMax
            hiringGoal
          }
        }
        ...BrandDetails_campaign
        ...ComissionRateDetails_campaign
        ...UseCase_campaign
      }
    `,
    campaign
  );

  const campaignWithType = prepareCampaignValue(data, [
    ...availableCampaignTypes,
  ]) as CampaignDetails_campaign$data;

  const {
    name,
    id: campaignId,
    type,
    postingType,
    platform,
    brief,
    objective,
    organization,
    useCase,
  } = campaignWithType;
  const hasNewCases = hasFeatureFlag('use_cases_5389') && data?.useCasesUnlocked;

  if (!brief) return null;

  const { id: briefId } = brief;

  const isCreatorAds = type === INFLUENCER && postingType === CREATOR_ADS;
  const isTTCreatorAds = isCreatorAds && platform === TIKTOK;

  const currency = data?.organization?.currency;

  const platformsList = getPlatformsList({ isCreatorAds, useCase });

  const objectivesList = getObjectivesList({ campaignId });
  const isTestOrg = data?.organization?.test;
  const paying = data?.organization?.paying;
  const trialMonthPlanAvailable = data?.organization?.trialMonthPlanAvailable;
  const psLicensedCreators = data?.organization?.counters?.psLicensedCreators;
  const maxPaidSocialActivationDays =
    data?.organization?.subscription?.effectiveLimits.maxPaidSocialActivationDays;

  const viewConditions = getViewConditions({
    campaign: data,
    canUseNewCases: hasNewCases,
    useCase: useCase,
  });

  useEffect(() => {
    if (type === CONTENT_CREATION || postingType === ORGANIC_POSTING) {
      handleBriefUpdate({
        paidSocial: false,
        paidSocialBca: false,
        paidSocialActivationDays: null,
        sparkAds: false,
        sparkAdsActivationDays: null,
      });
    }
  }, [type, postingType]);

  useEffect(() => {
    const errors = checkValidation(data, hasNewCases, tiktokShopAvailable);
    briefDispatch({ key: 'campaignInfoErrors', value: errors });
    briefDispatch({ key: 'campaignInfoFilled', value: errors.length === 0 });
  }, [data]);

  const handleBriefUpdate = (data: Partial<UpdateBriefInput>) => {
    briefDispatch({ key: 'briefIsSaving', value: true });
    updateBrief({ id: briefId, ...data });
  };

  const handleCampaignUpdate = (data: Partial<UpdateCampaignInput>) => {
    briefDispatch({ key: 'briefIsSaving', value: true });
    updateCampaign({ id: campaignId, ...data });
  };

  const handleObjectiveChange = (data: any) => {
    amplitude.sendEvent<393>({
      id: '393',
      category: 'brief',
      name: 'goal_click',
      param: {
        goal_type: data.objective,
        campaignId,
        isAdmin,
        organization_id: organization?.id,
        planId,
        isTestOrganization: !!isTestOrg,
      },
    });
    handleCampaignUpdate({ ...data, useCase: null });
  };

  const handleCampaignNameChange = (data: { name: string }) => {
    if (!paying && data.name && !name && currency === 'USD') {
      const callback = () => {
        identifyUser({ first_brief_status: 'started' });
      };

      addHubspotScript(callback);
    }
    handleCampaignUpdate({ name: data.name });
  };

  const handlePlatformChange = (data: { platform: string }) => {
    const newPlatform = data.platform;
    if (
      !(newPlatform === 'INSTAGRAM' || newPlatform === 'FACEBOOK') &&
      (brief.paidSocial || brief.paidSocialBca || brief.paidSocialActivationDays)
    ) {
      handleBriefUpdate({
        paidSocial: false,
        paidSocialBca: false,
        paidSocialActivationDays: null,
      });
    }
    if (newPlatform !== 'TIKTOK' && platform === 'TIKTOK' && brief.sparkAdsActivationDays) {
      handleBriefUpdate({ sparkAds: false, sparkAdsActivationDays: null });
    }

    handleCampaignUpdate({ platform: newPlatform as CampaignPlatform });
  };

  return (
    <Section titleMsg="brief_template.section.campaign_info">
      <Element name="brand">
        <BrandDetails campaign={data} isOwner={isOwner} isAdmin={isAdmin} />
      </Element>
      <Element name="name">
        <Field
          title="brief_template.field.campaign_name.title"
          description="brief_template.field.campaign_name.descr"
          name="name"
          element="input"
          elementData={{
            maxlength: 50,
            defaultValue: name,
            placeholderMsg: 'brief_template.field.campaign_name.placeholder',
            onBlur: handleCampaignNameChange,
            dataTest: 'input:campaignName',
            error: shownElementsWithPossibleError?.includes('name') && showErrors && !name,
          }}
          className={styles.field}
        />
      </Element>
      <UseCase
        campaign={data}
        objectiveVisible={viewConditions.objective}
        newCasesVisible={viewConditions.newCases}
        onCampaignUpdate={handleCampaignUpdate}
        onBriefUpdate={handleBriefUpdate}
      />
      <With condition={viewConditions.productSeedingDetailsVisible} name="productSeedingDetails">
        <ProductSeedingDetails />
      </With>
      <With condition={viewConditions.platform && platformsList.length > 1} name="platform">
        <Field
          title="brief_template.field.campaign_platform.title"
          description="brief_template.field.campaign_platform.descr"
        >
          <Element name="platform">
            <SegmentedControls
              error={
                shownElementsWithPossibleError?.includes('platform') && showErrors && !platform
              }
              id="platform"
              currentValue={platform}
              items={platformsList}
              onChange={handlePlatformChange}
            />
          </Element>
        </Field>
      </With>
      {briefId && (
        <With condition={viewConditions.partnershipDetails} forceRerender name="creatorAdsIg">
          <Element name="creatorAdsIg">
            <PartnershipAdsDetails
              brief={brief}
              psLicensedCreators={psLicensedCreators}
              trialMonthPlanAvailable={trialMonthPlanAvailable}
              maxPaidSocialActivationDays={maxPaidSocialActivationDays}
            />
          </Element>
        </With>
      )}
      <Element name="sparkAdsAwareness">
        <With condition={viewConditions.sparkAdsDetaild} name="creatorAdsTiktok">
          <Element name="creatorAdsTiktok">
            <SparkAdsDetails
              briefId={briefId}
              sparkAdsActivationDays={brief.sparkAdsActivationDays}
              hideDisabled={isTTCreatorAds}
            />
          </Element>
        </With>
      </Element>
      <HiringGoal
        brief={brief}
        onBriefUpdate={handleBriefUpdate}
        creatorsAmountVisible={viewConditions.creatorsAmount}
        exactCreatorAmountVisible={viewConditions.exactCreatorAmountVisible}
      />
      <With condition={viewConditions.wlAddonsBanner}>
        <WlSlotsBanner campaign={data} />
      </With>
      <With condition={!hasNewCases && viewConditions.objective}>
        <Field
          title="brief_template.field.campaign_objective.title"
          description="brief_template.field.campaign_objective.descr"
          isOptional={true}
        >
          <SegmentedControls
            id="objective"
            currentValue={objective}
            items={objectivesList}
            onChange={handleObjectiveChange}
          />
        </Field>
      </With>
      {!(tiktokShopAvailable && data.useCase === 'TIKTOK_SHOP') && (
        <Element name="fee">
          <ComissionRateDetails
            isAdmin={isAdmin}
            comissionAvailable={viewConditions.comissionAvailable}
            commissionInputVisible={viewConditions.commissionInputVisible}
            campaign={data}
            onBriefChange={handleBriefUpdate}
          />
        </Element>
      )}
    </Section>
  );
};

export default CampaignDetails;
