import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Buffer } from 'buffer';

import BriefContent from '../BriefContent/BriefContent';

import styles from './CampaignOutreachContent.pcss';
import CampaignOutreachContentQuery from './CampaignOutreachContent.Query';
import CampaignOutreachInactiveBanner from './CampaignOutreachInactiveBanner/CampaignOutreachInactiveBanner';

import { Auth0Context } from 'Containers/Auth0/Auth0Context';
import Spinner from 'Atoms/Spinner/Spinner';
import { CampaignOutreachContentQuery as QueryType } from 'GraphTypes/CampaignOutreachContentQuery.graphql';
import Button from 'Components/ui/Button/Button';
import Drawer from 'Components/ui/Drawer/Drawer';
import OutreachMobileBanner from 'Molecules/OutreachMobileBanner/OutreachMobileBanner';
import Error404 from 'Page/common/Error404/Error404';
import { amplitude } from 'Helpers/amplitude';
import { SIGNUP_CREATOR_ROUTE, CONTRACTOR_PROJECT_OFFER_ROUTE } from 'Constants/general';

interface Props {
  campaignId: string;
  outreachType?: 'invite' | 'campaign';
}

const CampaignOutreachContent: React.FC<Props> = (props) => {
  const { campaignId, outreachType } = props;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const code = searchParams.get('code');
  const { isRegisteredUser } = useContext(Auth0Context);
  const [outreachInvitedUserEmail, setOutreachInvitedUserEmail] = useState('');
  const [token, setToken] = useState('');
  const [invalidCode, setInvalidCode] = useState(false);

  useEffect(() => {
    if (code) {
      try {
        const additionalData = Buffer.from(code, 'base64').toString();
        const tokenData: { email: string; token: string } = JSON.parse(additionalData);
        setToken(tokenData.token);
        setOutreachInvitedUserEmail(tokenData.email);
      } catch (e) {
        setInvalidCode(true);
      }
    }
  }, [code]);

  useEffect(() => {
    if (token) {
      sessionStorage.setItem('outreachToken', token);
    }
    if (token && campaignId) {
      amplitude.sendEvent({
        id: '465',
        category: 'campaign',
        name: 'outreach_brief_viewed_not_signed',
        param: { campaign_id: campaignId },
      });
    }
  }, [token, campaignId]);

  const data = useLazyLoadQuery<QueryType>(CampaignOutreachContentQuery, {
    campaignId,
  });

  const [showMobileDrawer, setMobileDrawerStatus] = useState(false);

  const brandName = data?.campaign?.brand?.name || 'This brand';

  const handleApplyClick = useCallback(() => {
    amplitude.sendEvent({
      id: '223',
      category: 'creator',
      name: 'campaign_invite_apply_web',
    });

    if (outreachType === 'campaign') {
      setMobileDrawerStatus(true);
    } else if (outreachType === 'invite') {
      amplitude.sendEvent({
        id: '466',
        category: 'campaign',
        name: 'outreach_apply_clicked_not_signed',
        param: { campaign_id: campaignId },
      });

      if (token) {
        if (isRegisteredUser) {
          setTimeout(() => {
            window.open(`${CONTRACTOR_PROJECT_OFFER_ROUTE}?token=${token}`, '_self');
          }, 100);
        } else {
          navigate(SIGNUP_CREATOR_ROUTE, {
            state: { outreachEmail: outreachInvitedUserEmail, brandName },
          });
        }
      }
    }
  }, [outreachInvitedUserEmail]);

  const handleCloseMobileDrawer = useCallback(() => {
    setMobileDrawerStatus(false);
  }, []);

  const handleSeeOtherClick = useCallback(() => {
    setMobileDrawerStatus(true);
  }, []);

  if (!data) {
    return <Spinner style={{ margin: '40px auto' }} />;
  }

  const campaign = data?.campaign;
  if (!campaign || invalidCode) {
    return <Error404 />;
  }

  const isCampaignActive = data?.campaign?.stage === 'ACTIVE';

  return (
    <div className={styles.root}>
      {!isCampaignActive && <CampaignOutreachInactiveBanner />}
      <BriefContent campaign={campaign} isCreator={true} compactView={true} />
      <div className={styles.actionsContainer}>
        {isCampaignActive ? (
          <Button
            onClick={handleApplyClick}
            color="black"
            fluid
            msg="outreach_campaign.active.button"
            className={styles.actionsContainerButton}
          />
        ) : (
          <Button
            onClick={handleSeeOtherClick}
            color="black"
            fluid
            msg="outreach_campaign.inactive.button"
            className={styles.actionsContainerButton}
          />
        )}
      </div>
      <Drawer
        rootKey="outreach-mobile"
        opened={showMobileDrawer}
        onClose={handleCloseMobileDrawer}
        className={styles.mobileDrawer}
        needCloseButton
      >
        <OutreachMobileBanner campaignId={campaignId} outreachType={outreachType} />
      </Drawer>
    </div>
  );
};

export default CampaignOutreachContent;
