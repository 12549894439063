import React, { useEffect, MouseEvent } from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import { Element } from 'react-scroll';

import styles from './CreatorMedias.css';
import Portfolio from './Portfolio/Portfolio';
import Posts from './Posts/Posts';
import MediasTabs from './MediasTabs/MediasTabs';
import InsightsStoriesMedias from './InsightsStoriesMedias/InsightsStoriesMedias';

import Spinner from 'Atoms/Spinner/Spinner';
import environment from 'Api/Environment';
import { CreatorMediasQuery as QueryType } from 'GraphTypes/CreatorMediasQuery.graphql';
import { trackTime as custifyTrackTime } from 'Util/custify';

const CreatorMediasQuery = graphql`
  query CreatorMediasQuery($creatorId: ID!) {
    creator(id: $creatorId) {
      ...Portfolio_creator
      ...Posts_creator
      ...MediasTabs_creator
      ...InsightsPostMedias_creator
      ...InsightsStoriesMedias_creator
      ... on InstagramCreator {
        userInsightsData {
          id
        }
      }
      portfolioItems {
        totalCount
      }
      profile {
        topPosts {
          link
        }
      }
    }
    currentUser {
      admin
      currency
      permissions
      organization {
        churned
        newClient
        subscription {
          paused
        }
      }
    }
  }
`;

interface Props {
  creatorId: string;
  className?: string;
  onUnlockBannerShow: (e: MouseEvent<HTMLDivElement>) => void;
}

const CreatorMedias: React.FC<Props> = (props) => {
  const { className, creatorId, onUnlockBannerShow } = props;

  const tabsData = {
    portfolio: 'mediaPortfolio',
    stories: 'mediaStories',
    posts: 'mediaPosts',
    tabsContainer: 'statisticMedias',
  };

  useEffect(() => {
    return () => {
      _ctrack.stopTrackTime();
    };
  }, []);

  return (
    <div className={className} id="statisticMedias">
      <QueryRenderer<QueryType>
        environment={environment}
        query={CreatorMediasQuery}
        variables={{ creatorId }}
        render={({ props: queryProps }) => {
          if (!queryProps) return <Spinner className={styles.spinner} />;

          const creator = queryProps.creator;
          const currentUser = queryProps.currentUser;
          const admin = currentUser?.admin;

          if (!creator) return null;

          const portfolioItems = creator.portfolioItems.totalCount;
          const userInsightsData = creator?.userInsightsData?.id;

          const permissions = currentUser?.permissions;
          const isChurned = currentUser?.organization?.churned;
          const isNewClient = currentUser?.organization?.newClient;
          const isPlanPaused = Boolean(currentUser?.organization?.subscription?.paused);

          const isBlockedView =
            !admin &&
            (isChurned || isNewClient || isPlanPaused) &&
            !permissions?.includes('CREATOR_PROFILES__MODERATE');

          custifyTrackTime('Creators_profile');

          return (
            <div>
              <MediasTabs creator={creator} tabsData={tabsData} />
              <div>
                {Number(portfolioItems) > 0 && (
                  <Element name={tabsData.portfolio}>
                    <Portfolio creator={creator} />
                  </Element>
                )}
                {userInsightsData && (
                  <Element name={tabsData.stories}>
                    <InsightsStoriesMedias
                      creator={creator}
                      isBlockedView={isBlockedView}
                      onUnlockBannerShow={onUnlockBannerShow}
                    />
                  </Element>
                )}
                <Element name={tabsData.posts}>
                  <Posts
                    creator={creator}
                    isBlockedView={isBlockedView}
                    onUnlockBannerShow={onUnlockBannerShow}
                  />
                </Element>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};

export default CreatorMedias;
