import type { IntlShape } from 'react-intl';

import { BLOGGER, COMMUNITY, CELEBRITY, BRAND, RUB, USD, CAD } from 'Constants/general';
import { Currency } from 'GraphTypes/CreatorAdminPanelQuery.graphql';

type AccountTypesOptType = {
  intl: IntlShape;
  checkedValue?: string;
};

export const getAccountTypeOpt = ({ intl, checkedValue }: AccountTypesOptType) => {
  return [
    {
      value: intl.formatMessage({ id: 'creator.blogger' }),
      id: BLOGGER,
      isChecked: checkedValue === BLOGGER,
    },
    {
      value: intl.formatMessage({ id: 'creator.community' }),
      id: COMMUNITY,
      isChecked: checkedValue === COMMUNITY,
    },
    {
      value: intl.formatMessage({ id: 'creator.celebrity' }),
      id: CELEBRITY,
      isChecked: checkedValue === CELEBRITY,
    },
    {
      value: intl.formatMessage({ id: 'creator.brand' }),
      id: BRAND,
      isChecked: checkedValue === BRAND,
    },
  ];
};

type CurrencyTypesOptType = {
  intl: IntlShape;
  checkedValue?: Currency | null;
};

export const getCurrencyOpt = ({ checkedValue }: CurrencyTypesOptType) => {
  return [
    { id: USD, value: USD, isChecked: checkedValue === USD },
    { id: RUB, value: RUB, isChecked: checkedValue === RUB },
    { id: CAD, value: CAD, isChecked: checkedValue === CAD },
  ];
};
