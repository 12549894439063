import React, { useCallback, useState } from 'react';
import { createPaginationContainer, graphql, RelayPaginationProp } from 'react-relay';

import styles from './Campaigns.pcss';

import { Bars } from 'CommonPage/Dashboard/AdvertiserDashboard/Campaigns/CampaignsList/CampaignsList';
import CampaignStub from 'CommonPage/Dashboard/AdvertiserDashboard/Campaigns/CampaignsList/CampaignStub/CampaignStub';
import LoaderHandler from 'Organisms/LoaderHandler/LoaderHandler';
import Campaign from 'CommonPage/Dashboard/AdvertiserDashboard/Campaigns/CampaignsList/Campaign/Campaign';
import Drawer from 'Components/ui/Drawer';
import DuplicateCampaign from 'Modal/advertiser/DuplicateCampaign/DuplicateCampaign';
import CampaignActionsModal from 'CommonPage/Dashboard/AdvertiserDashboard/Campaigns/CampaignsList/Campaign/CampaignPreviewActions/CampaignActionsModal/CampaignActionsModal';
import { Campaign_campaign$data, CampaignStage } from 'GraphTypes/Campaign_campaign.graphql';
import { Campaigns_organization$data } from 'GraphTypes/Campaigns_organization.graphql';
import { AdvertiserCampaignsQuery$data } from 'GraphTypes/AdvertiserCampaignsQuery.graphql';

const CAMPAIGNS_COUNT = 18;
const FOOTER_OFFSET = 279;

interface Props {
  organizationId: string;
  textQuery?: string;
  userOrganizationId: string;
  stages?: CampaignStage[] | null;
  organization: Campaigns_organization$data;
  counters?:
    | NonNullable<
        NonNullable<AdvertiserCampaignsQuery$data['currentUser']>['organization']
      >['counters']
    | null;
  relay: RelayPaginationProp;
}

const Campaigns: React.FC<Props> = (props) => {
  const {
    relay,
    counters,
    organization: { campaigns },
    userOrganizationId,
    stages,
  } = props;

  const [activeCampaign, setActiveCampaign] = useState<Campaign_campaign$data | null>(null);
  const [activeCampaignId, setActiveCampaignId] = useState('');

  const handleActiveCampaignChange = (campaignData: Campaign_campaign$data | null) => {
    if (!campaignData) return;

    setActiveCampaign(campaignData);
  };

  const handleCampaignMenu = (data: Campaign_campaign$data) => {
    setActiveCampaignId(data.id);
    setActiveCampaign(data);
  };

  const canDownloadShipment =
    activeCampaign?.brief?.productDelivery === 'BRAND_WILL_HANDLE_SHIPMENT_TO_CREATORS';

  const closeCampaignMenu = useCallback(() => {
    setActiveCampaign(null);
  }, []);

  const stage = stages?.[0];

  return (
    <div className={styles.container}>
      <Bars stage={stage} counters={counters} />
      <LoaderHandler
        relay={relay}
        count={CAMPAIGNS_COUNT}
        offset={FOOTER_OFFSET}
        preloader={<CampaignStub stage={stage} />}
      >
        {campaigns?.edges?.map((item) => {
          if (!item?.node) return null;
          const { id } = item.node;
          return (
            <Campaign
              key={id}
              isAdmin
              campaign={item.node}
              organizationId={userOrganizationId}
              handleCampaignMenu={handleCampaignMenu}
            />
          );
        })}
        <Drawer
          rootKey={'admin-campaign-menu'}
          opened={!!activeCampaign}
          onClose={closeCampaignMenu}
          needCloseButton={false}
        >
          {activeCampaign && (
            <CampaignActionsModal
              campaign={activeCampaign}
              setActiveCampaign={handleActiveCampaignChange}
              handleClose={closeCampaignMenu}
              isAdmin
              canDownloadShipment={canDownloadShipment}
              canBrowseCreators
            />
          )}
        </Drawer>
        <DuplicateCampaign campaignId={activeCampaignId} />
      </LoaderHandler>
    </div>
  );
};

export default createPaginationContainer(
  Campaigns,
  {
    organization: graphql`
      fragment Campaigns_organization on Organization
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 18 }
        cursor: { type: "String" }
        textQuery: { type: "String" }
        stages: { type: "[CampaignStage!]" }
      ) {
        campaigns(first: $count, after: $cursor, stages: $stages, textQuery: $textQuery)
          @connection(key: "Campaigns_campaigns", filters: ["stages", "textQuery"]) {
          edges {
            node {
              id
              ...Campaign_campaign
              brief {
                ... on V2Brief {
                  productDelivery
                }
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.organization && props.organization.campaigns;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
        organizationId: props.organizationId,
        stages: props.stages,
        textQuery: props.textQuery,
      };
    },
    query: graphql`
      query CampaignsPaginationQuery(
        $organizationId: ID!
        $count: Int!
        $cursor: String
        $textQuery: String
        $stages: [CampaignStage!]
      ) {
        organization(id: $organizationId) {
          ...Campaigns_organization
            @arguments(count: $count, cursor: $cursor, stages: $stages, textQuery: $textQuery)
        }
      }
    `,
  }
);
