import React, { Suspense } from 'react';
import track from 'react-tracking';

import ListingBrief from './ListingBrief/ListingBrief';
import ProjectBrief from './ProjectBrief/ProjectBrief';
import ProjectForOffer from './ProjectForOffer/ProjectForOffer';
import MobileBrief from './MobileBrief/MobileBrief';
import CampaignBrief from './CampaignBrief/CampaignBrief';
import CampaignOutreachContent from './CampaignOutreachContent/CampaignOutreachContent';

interface Props {
  campaignId?: string;
  projectId?: string;
  isOwnership?: boolean;
  hasOwnership?: boolean;
  listingId?: string;
  projectForOfferId?: string;
  hasActions?: boolean;
  mobileView?: boolean;
  rejectCallback?: () => void;
  outreachType?: 'invite' | 'campaign';
  shortenView?: boolean;
  showAcceptActionOnly?: boolean;
}

const Brief: React.FC<Props> = (props) => {
  const {
    campaignId,
    projectId,
    isOwnership,
    hasOwnership,
    listingId,
    projectForOfferId,
    mobileView,
    outreachType,
    shortenView,
    showAcceptActionOnly,
  } = props;

  if (listingId) {
    return <ListingBrief listingId={listingId} />;
  }

  if (outreachType && campaignId) {
    return <CampaignOutreachContent campaignId={campaignId} outreachType={outreachType} />;
  }

  if (projectForOfferId) {
    return (
      <ProjectForOffer
        isOwnership={isOwnership}
        hasOwnership={hasOwnership}
        projectForOfferId={projectForOfferId}
        shortenView={shortenView}
        showAcceptActionOnly={showAcceptActionOnly}
      />
    );
  }

  if (projectId) {
    return <ProjectBrief projectId={projectId} />;
  }

  if (campaignId && mobileView) {
    return (
      <Suspense fallback={null}>
        <MobileBrief campaignId={campaignId} />
      </Suspense>
    );
  }

  if (campaignId) {
    return <CampaignBrief campaignId={campaignId} />;
  }

  return null;
};

export default track({ section: 'brief_preview' })(Brief);
