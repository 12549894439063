import { graphql } from 'react-relay';

import { PreviousCollaborationsQuery as QueryType } from 'GraphTypes/PreviousCollaborationsQuery.graphql';
import type { Collaborations_organization$key } from 'GraphTypes/Collaborations_organization.graphql';

const PreviousCollaborationsQuery = graphql`
  query PreviousCollaborationsQuery($campaignIds: [ID!], $brandIds: [ID!], $textQuery: String) {
    currentUser {
      organization {
        id
        ...Collaborations_organization
          @arguments(campaignIds: $campaignIds, brandIds: $brandIds, textQuery: $textQuery)
      }
    }
  }
`;

export { PreviousCollaborationsQuery };

// types

type PreviousCollaborationsQueryType = QueryType & {
  readonly response: QueryType['response'] & {
    readonly currentUser:
      | (QueryType['response']['currentUser'] & {
          readonly organization: Collaborations_organization$key | null;
        })
      | null;
  };
};

export type { PreviousCollaborationsQueryType };
