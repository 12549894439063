import React from 'react';

import PortfolioGroup from '../PortfolioGroup/PortfolioGroup';

import styles from './PortfolioGroups.pcss';

import { Portfolio_creator$data } from 'GraphTypes/Portfolio_creator.graphql';

type Props = {
  portfolioGroups: Portfolio_creator$data['portfolioGroups'];
};

const PortfolioGroups = (props: Props) => {
  const { portfolioGroups } = props;
  if (!portfolioGroups?.length) return null;
  return (
    <div className={styles.root}>
      {portfolioGroups.map((group) => (
        <PortfolioGroup key={group.type?.name} group={group} />
      ))}
    </div>
  );
};

export default PortfolioGroups;
